import { AxiosInstance } from "axios";

import { ServiceAgreementID, CustomerID, StatusID, DepartmentID,
    ServiceAgreementTypeID, AgreementResponsibleName, AgreementResponsibleEmail,
    AgreementResponsiblePhone, Requisitionnumber, Policenumber, YearlyServiceFee,
    StartDate, BindingPeriod, ContractArea, DiscountAmount,
    OriginalServiceAgreementID, SmartAgreement, SalesmanNo, ChangedDate, ChangedBy,
    CreatedDate, CreatedBy, Deleted, ServiceAgreementDTO } from './types/serviceAgreement.type';
import defaultClass from './defaultClass';
import DeliveryAddress from "./deliveryAddress";
import ServiceAgreementItem from "./serviceAgreementItem";
import { urls } from './types/default.type';

export default class ServiceAgreement extends defaultClass {
    protected _model: ServiceAgreementDTO;
    protected _primaryKey: string;
    protected _urls: urls;

    constructor(api: AxiosInstance, serviceAgreementId: ServiceAgreementID = null) {
        super(api);
        this._model = {
            ServiceAgreementID: null,
            CustomerID: null,
            StatusID: null,
            DepartmentID: null,
            ServiceAgreementTypeID: null,
            AgreementResponsibleName: null,
            AgreementResponsibleEmail: null,
            AgreementResponsiblePhone: null,
            Requisitionnumber: null,
            Policenumber: null,
            YearlyServiceFee: null,
            StartDate: null,
            BindingPeriod: null,
            ContractArea: null,
            DiscountAmount: null,
            OriginalServiceAgreementID: null,
            SmartAgreement: null,
            SalesmanNo: null,
            ChangedDate: null,
            ChangedBy: null,
            CreatedDate: null,
            CreatedBy: null,
            Deleted: null,
            DeliveryAddress: [],
            ServiceAgreementItems: [],
        }

        this._primaryKey = 'ServiceAgreementID';

        this._urls = {
            get: 'service-agreement/{ServiceAgreementID}',
            post: 'service-agreement',
            delete: 'service-agreement/{ServiceAgreementID}',
            put: 'service-agreement/{ServiceAgreementID}',
        };

        if (serviceAgreementId) {
            this._model.ServiceAgreementID = serviceAgreementId;
            this._get();
        }
    }

    private _fill(serviceAgreement: ServiceAgreementDTO) {
        this._model = serviceAgreement;
    }

    get ServiceAgreementID(): ServiceAgreementID {
        return this._model.ServiceAgreementID;
    }

    get CustomerID(): CustomerID {
        return this._model.CustomerID;
    }

    get StatusID(): StatusID {
        return this._model.StatusID;
    }

    get DepartmentID(): DepartmentID {
        return this._model.DepartmentID;
    }

    get ServiceAgreementTypeID(): ServiceAgreementTypeID {
        return this._model.ServiceAgreementTypeID;
    }

    get AgreementResponsibleName(): AgreementResponsibleName {
        return this._model.AgreementResponsibleName;
    }

    get AgreementResponsibleEmail(): AgreementResponsibleEmail {
        return this._model.AgreementResponsibleEmail;
    }

    get AgreementResponsiblePhone(): AgreementResponsiblePhone {
        return this._model.AgreementResponsiblePhone;
    }

    get Requisitionnumber(): Requisitionnumber {
        return this._model.Requisitionnumber;
    }

    get Policenumber(): Policenumber {
        return this._model.Policenumber;
    }

    get YearlyServiceFee(): YearlyServiceFee {
        return this._model.YearlyServiceFee;
    }

    get StartDate(): StartDate {
        return this._model.StartDate;
    }

    get BindingPeriod(): BindingPeriod {
        return this._model.BindingPeriod;
    }

    get ContractArea(): ContractArea {
        return this._model.ContractArea;
    }

    get DiscountAmount(): DiscountAmount {
        return this._model.DiscountAmount;
    }

    get OriginalServiceAgreementID(): OriginalServiceAgreementID {
        return this._model.OriginalServiceAgreementID;
    }

    get SmartAgreement(): SmartAgreement {
        return this._model.SmartAgreement;
    }

    get SalesmanNo(): SalesmanNo {
        return this._model.SalesmanNo;
    }

    get ChangedDate(): ChangedDate {
        return this._model.ChangedDate;
    }

    get ChangedBy(): ChangedBy {
        return this._model.ChangedBy;
    }

    get CreatedDate(): CreatedDate {
        return this._model.CreatedDate;
    }

    get CreatedBy(): CreatedBy {
        return this._model.CreatedBy;
    }

    get Deleted(): Deleted {
        return this._model.Deleted;
    }

    set ServiceAgreementID(value: ServiceAgreementID) {
        this._model.ServiceAgreementID = value;
    }

    set CustomerID(value: CustomerID) {
        this._model.CustomerID = value;
    }

    set StatusID(value: StatusID) {
        this._model.StatusID = value;
    }

    set DepartmentID(value: DepartmentID) {
        this._model.DepartmentID = value;
    }

    set ServiceAgreementTypeID(value: ServiceAgreementTypeID) {
        this._model.ServiceAgreementTypeID = value;
    }

    set AgreementResponsibleName(value: AgreementResponsibleName) {
        this._model.AgreementResponsibleName = value;
    }

    set AgreementResponsibleEmail(value: AgreementResponsibleEmail) {
        this._model.AgreementResponsibleEmail = value;
    }

    set AgreementResponsiblePhone(value: AgreementResponsiblePhone) {
        this._model.AgreementResponsiblePhone = value;
    }

    set Requisitionnumber(value: Requisitionnumber) {
        this._model.Requisitionnumber = value;
    }

    set Policenumber(value: Policenumber) {
        this._model.Policenumber = value;
    }

    set YearlyServiceFee(value: YearlyServiceFee) {
        this._model.YearlyServiceFee = value;
    }

    set StartDate(value: StartDate) {
        this._model.StartDate = value;
    }

    set BindingPeriod(value: BindingPeriod) {
        this._model.BindingPeriod = value;
    }

    set ContractArea(value: ContractArea) {
        this._model.ContractArea = value;
    }

    set DiscountAmount(value: DiscountAmount) {
        this._model.DiscountAmount = value;
    }

    set OriginalServiceAgreementID(value: OriginalServiceAgreementID) {
        this._model.OriginalServiceAgreementID = value;
    }

    set SmartAgreement(value: SmartAgreement) {
        this._model.SmartAgreement = value;
    }

    set SalesmanNo(value: SalesmanNo) {
        this._model.SalesmanNo = value;
    }

    set ChangedDate(value: ChangedDate) {
        this._model.ChangedDate = value;
    }

    set ChangedBy(value: ChangedBy) {
        this._model.ChangedBy = value;
    }

    set CreatedDate(value: CreatedDate) {
        this._model.CreatedDate = value;
    }

    set CreatedBy(value: CreatedBy) {
        this._model.CreatedBy = value;
    }

    set Deleted(value: Deleted) {
        this._model.Deleted = value;
    }

    set DeliveryAddress(value: DeliveryAddress) {
        if (this._model.DeliveryAddress) {
            this._model.DeliveryAddress.push(value);
        }
    }

    set ServiceAgreementItem(value: ServiceAgreementItem) {
        if (this._model.ServiceAgreementItems) {
            this._model.ServiceAgreementItems.push(value);
        }
    }

    
}

