
    import { defineComponent } from 'vue';

    export default defineComponent({
        components: {
        },
        emits: ['update:modelValue', 'update:errors', 'update'],
        props: {
            modelValue: String,
            errors: Boolean,
            label: String,
            id: String,
            readonly: {
                type: Boolean,
                required: false,
                default: false
            },
            rules: String
        },
        data() {
            return {
                errorMessage: '',
            }
        },
        mounted() {
        },
        methods: {
            async handleBlur() {
                this.$root?.validateField(this.label ?? '', this.modelValue ?? '', this.rules ?? '').then((validation: any) => {
                    this.$emit('update:errors', validation.errors);
                    this.errorMessage = validation.message;
                });
            },

            handleInput(event: any) {
                this.$emit('update:modelValue', event.target.value);
                this.$emit('update', this.id);
            }
        },
    });
