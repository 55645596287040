import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SearchModal = _resolveComponent("SearchModal")!

  return (_ctx.display)
    ? (_openBlock(), _createBlock(_component_SearchModal, {
        key: 0,
        primaryKey: _ctx.primaryKey,
        excludedKeys: _ctx.excludedKeys,
        searchPath: _ctx.searchPath,
        text: _ctx.text,
        title: _ctx.title,
        enableX: _ctx.enableX,
        okBtn: _ctx.okBtn,
        cancelBtn: _ctx.cancelBtn,
        onOk: _ctx.ok,
        onCancel: _ctx.cancel
      }, null, 8, ["primaryKey", "excludedKeys", "searchPath", "text", "title", "enableX", "okBtn", "cancelBtn", "onOk", "onCancel"]))
    : _createCommentVNode("", true)
}