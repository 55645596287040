import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-68294381"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  ref: "hoursListRef",
  class: "v3dp__column"
}
const _hoisted_2 = ["disabled", "onClick"]
const _hoisted_3 = {
  ref: "minutesListRef",
  class: "v3dp__column"
}
const _hoisted_4 = ["disabled", "onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_picker_popup = _resolveComponent("picker-popup")!

  return (_openBlock(), _createBlock(_component_picker_popup, {
    headingClickable: "",
    columnCount: 2,
    leftDisabled: true,
    rightDisabled: true,
    onHeading: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('back')))
  }, {
    heading: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.padStartZero(_ctx.hours)) + ":" + _toDisplayString(_ctx.padStartZero(_ctx.minutes)), 1)
    ]),
    body: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.hoursList, (item) => {
          return (_openBlock(), _createElementBlock("button", {
            key: item.value,
            ref_for: true,
            ref: item.ref,
            class: _normalizeClass({ selected: item.selected }),
            disabled: !_ctx.isEnabled(item.date),
            onClick: _withModifiers(($event: any) => (_ctx.hours=item.value), ["stop","prevent"])
          }, [
            _createElementVNode("span", null, _toDisplayString(_ctx.padStartZero(item.value)), 1)
          ], 10, _hoisted_2))
        }), 128))
      ], 512),
      _createElementVNode("div", _hoisted_3, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.minutesList, (item) => {
          return (_openBlock(), _createElementBlock("button", {
            key: item.value,
            ref_for: true,
            ref: item.ref,
            class: _normalizeClass({ selected: item.selected }),
            disabled: !_ctx.isEnabled(item.date),
            onClick: _withModifiers(($event: any) => (_ctx.selectMinutes(item)), ["stop","prevent"])
          }, [
            _createElementVNode("span", null, _toDisplayString(_ctx.padStartZero(item.value)), 1)
          ], 10, _hoisted_4))
        }), 128))
      ], 512)
    ]),
    _: 1
  }))
}