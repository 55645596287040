
    import { defineComponent } from 'vue';
    export default defineComponent({
        props: [
            'steps',// object with title as key
            'currentStep', // the current selected step
            'maxStep', // the furthest step in the progress that have been reached
            'disabled' // if it should be disabled 
        ],
        data () {
            return {
            }
        },
        methods: {
            clicked: function (stepId: number, event: MouseEvent) {
                if (this.disabled) return;
                console.log('clicked', stepId, this.maxStep, this.disabled);
                if (stepId <= this.maxStep) {
                    this.$emit('stepClicked', stepId);
                }
            }
        }
    });
