
import { PropType, defineComponent } from 'vue';
import { SaveResponse } from '../helpers/CreateJobTypes';
export default defineComponent({
    emits: ['update:modelValue', 'startOver'],
    components: {
    },
    async mounted() {
        this.ready = false;
        
        this.ready = true;
        return
    },
    props: {
        modelValue: {
            type: Object as PropType<SaveResponse>,
            required: true
        },
    },
    data() {
        return {
            ready: false
        }
    },
    methods: {
    },
});
