import { createRouter, createWebHistory } from "vue-router";

import CreateServiceContractHome from "../modules/CreateServiceContract/views/Home.vue";
import CreateJobHome from "../modules/CreateJob/views/Home.vue";
import CustomerLookupHome from "../modules/CustomerLookup/views/Home.vue";
import CustomerLookupView from "../modules/CustomerLookup/views/View.vue";
import PageNotFound from "../modules/Errors/PageNotFound.vue";
import TestPage from "../modules/Errors/TestPage.vue";
import LandingPage from "../modules/AppSuite/views/LandingPage.vue";
import ServiceAppHome from "../modules/ServiceApp/views/Home.vue";
import EngineRulesHome from "../modules/EngineRules/views/Home.vue";

import { registerGuard } from "./Guard";

const routes = [
    {
        path: "/",
        alias: "/auth/openid/return",
        name: "ax_app_suite",
        component: LandingPage,
        meta: {
            title: "Anticimex App Suite",
            showMenuButton: true,
            requiresAuth: true
        }
    },
    {
        path: "/OpretServiceKontrakt/:stepName",
        name: "create_servicecontract_named",
        component: CreateServiceContractHome,
        meta: {
            title: "Opret Servicekontrakt",
            showMenuButton: true,
            requiresAuth: true,
            role: "servicecontract.writer"
        }
    },
    {
        path: "/OpretServiceKontrakt",
        name: "create_servicecontract",
        component: CreateServiceContractHome,
        meta: {
            title: "Opret Servicekontrakt",
            showMenuButton: true,
            requiresAuth: true,
            role: "servicecontract.writer"
        }
    },
    {
        path: "/OpretOpgave/:stepName",
        name: "create_job_named",
        component: CreateJobHome,
        meta: {
            showMenuButton: true,
            requiresAuth: true,
            title: "Opret Opgave",
            role: "job.writer"
        }
    },
    {
        path: "/KundeOpslag/:id/:step?/:agreementID?/:taskID?",
        name: "customer_lookup_view",
        component: CustomerLookupView,
        meta: {
            title: "Kunde opslag",
            showMenuButton: true,
            requiresAuth: true,
            role: "customer.reader"
        }
    },
    {
        path: "/OpretOpgave",
        name: "create_job",
        component: CreateJobHome,
        meta: {
            title: "Opret Opgave",
            showMenuButton: true,
            requiresAuth: true,
            role: "job.writer"
        }
    },
    {
        path: "/KundeOpslag/søg/:query?/:fields?",
        name: "customer_lookup_search",
        component: CustomerLookupHome,
        meta: {
            title: "Kunde opslag",
            showMenuButton: true,
            requiresAuth: true,
            role: "customer.reader"
        }
    },
    {
        path: "/KundeOpslag",
        name: "customer_lookup_main",
        component: CustomerLookupHome,
        meta: {
            title: "Kunde opslag",
            showMenuButton: true,
            requiresAuth: true,
            role: "customer.reader"
        }
    },    
    {
        path: "/ServiceApp",
        name: "service_app",
        component: ServiceAppHome,
        meta: {
            title: "Service App",
            showMenuButton: true,
            requiresAuth: true,
            role: "serviceapp.reader"
        }
    },
    {
        path: "/MotorRegler",
        name: "engine_rules",
        component: EngineRulesHome,
        meta: {
            title: "Motor Regler",
            showMenuButton: true,
            requiresAuth: true,
            role: "enginerules.reader"
        }
    },
    {
        path: "/:catchAll(.*)",
        component: PageNotFound,
        meta: {
            title: "Siden kunne ikke findes",
            showMenuButton: true,
            requiresAuth: true
        }
    },
    {
        path: "/test",
        component: TestPage,
        meta: {
            title: "Test",
            showMenuButton: true,
            requiresAuth: false
        }
    }
]

const routerHistory = createWebHistory();
const router = createRouter({
  history: routerHistory,
  routes: routes,
});

registerGuard(router);

export default router;