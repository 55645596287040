
  import { defineComponent } from 'vue';
  import AppSelector from '@/components/widgets/AppSelector.vue';

  export default defineComponent({
    components: {
    AppSelector
},
    props: {
        title: String,
        showMenuButton: Boolean,
        currentUser: Object,
        currentPage: String
    },
    data() {
        return {
            showAppMenu: false,
        }
    },
    methods: {
        toggleAppMenu() {
            if (this.showAppMenu) {
                this.closeAppMenu();
                return;
            }

            setTimeout(() => {
                this.showAppMenu = true;
            }, 0);
        },

        closeAppMenu() {
            this.showAppMenu = false;
        },

        logOut() {
            console.log('LogOut');
            this.emitter.emit('logout');
        },

        goToFrontpage() {
            this.$router.push('/');
        }
    }
  });

  