import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_b_icon_search = _resolveComponent("b-icon-search")!
  const _component_LoadCustomerModal = _resolveComponent("LoadCustomerModal")!
  const _component_LoadContractModal = _resolveComponent("LoadContractModal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("button", {
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.loadCustomer && _ctx.loadCustomer(...args))),
      class: "btn btn-link",
      id: "load-customer"
    }, [
      _createVNode(_component_b_icon_search),
      _createTextVNode("Indlæs eksisterende kunde")
    ]),
    _createElementVNode("button", {
      onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.loadContact && _ctx.loadContact(...args))),
      class: "btn btn-link",
      id: "load-Contract"
    }, [
      _createVNode(_component_b_icon_search),
      _createTextVNode("Indlæs fra servicekontrakt")
    ]),
    _createVNode(_component_LoadCustomerModal, {
      display: _ctx.displayCustomerModal,
      "onClick:cancel": _ctx.closeCustomerModal,
      "onClick:ok": _ctx.selectCustomer
    }, null, 8, ["display", "onClick:cancel", "onClick:ok"]),
    _createVNode(_component_LoadContractModal, {
      display: _ctx.displayContractModal,
      "onClick:cancel": _ctx.closeContractModal,
      "onClick:ok": _ctx.selectContract
    }, null, 8, ["display", "onClick:cancel", "onClick:ok"])
  ], 64))
}