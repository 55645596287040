
    import { defineComponent } from 'vue';

    export default defineComponent({
        components: {
        },
        emits: ['update:modelValue', 'update:errors', 'update', 'click'],
        props: {
            modelValue: String,
            label: String,
            compact: {
                type: Boolean,
                required: false,
                default: false
            },
            showLabel: {
                type: Boolean,
                required: false,
                default: true
            },
            action: {
                type: String,
                required: false,
            }, 
            display: {
                type: String,
                required: false,
                default: 'block'
            }
        },
        data() {
            return {
                errorMessage: '',
                id: 'button',
            }
        },
        mounted() {
        },
        methods: {
            async handleBlur() {
                this.$root?.validateField(this.label ?? '', this.modelValue ?? '',  '').then((validation: any) => {
                    this.$emit('update:errors', validation.errors);
                    this.errorMessage = validation.message;
                });
            },

            handleInput(event: any) {
                this.$emit('update:modelValue', event.target.value);
                this.$emit('update', this.id);
            },
        },
    });
