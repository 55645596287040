
import { defineComponent, ref } from 'vue';
import TextInput from '@/components/inputs/TextInput.vue';
import LaravelPagination from '@/components/widgets/LaravelPagination/LaravelPagination.vue';


export default defineComponent({
    components: {
        TextInput, 
        LaravelPagination
    },
    data: function () {
        return {
            searchString: "" as String,
            loading: true,
            error: false,
            searchStringProxy: "" as String,
            searchableFields: [] as SearchableField[]
        };
    },
    watch: {
        searchString() { 
            this.search();
        },
        searchableFields:{ 
            handler() {         
                this.search();
            },deep:true
        }
    },
    computed: {
        config():any {
            return {
                url: "customerlookup/search",
                method: 'get',
                params: { fields: this.fieldsToSearch }
            };
        },
        // The selected fields to search for
        fieldsToSearch: {
            get() {
                type resultType = {
                    table?: String,
                    fields?: String[]
                }
                const result: resultType = {};
                for (const table of this.searchableFields) {
                    if (!table.selected) continue;
                    result['table'] = table.table;
                    result['fields'] = [];
                    for (const field of table.fields) {
                        if (field.selected) {
                            result.fields.push(field.field);
                        }
                    }
                }
                if (!result.table || !(result?.fields?.length ?? 0 > 0)) {
                    return false;
                }
                return result;
            },
            set(value: any) {
                const table = this.searchableFields.find(x => x.table == value.table);
                if (table) {
                    table.selected = true
                    const fields = table.fields.filter(x => value.fields.includes(x.field));
                    fields.map(x => x.selected = true);
                    const notFields = table.fields.filter(x => !value.fields.includes(x.field));
                    notFields.map(x => x.selected = false);
                }
            }
        },
    },
    async mounted() {
        // load the searchable fields
        await this.getFields(); 
        // load any parameters from the route
        const params = this.$router.currentRoute.value.params;
        if (params.query) {
            this.searchString = String(params.query);
            this.fieldsToSearch = JSON.parse(String(params.fields));
        }
        if (params.query) {
        }
    }, 
    methods: {
        search() {
            this.searchStringProxy = this.searchString;
            if (this.$root) { 
                this.$root.$data.lastSearch = this.config;
            }

            // call the pagination (which contains the search)
            let childRef = ref<InstanceType<typeof LaravelPagination>>();
            if (childRef) { 
                childRef.value?.debouncedLookup?.()
            }
            const fields = JSON.stringify(this.config?.params?.fields);
            const query = String(this.searchString);
            this.$router.replace({ name: 'customer_lookup_search', params: { query, fields } });
        },
        /**
         * Go to the view contianing info about the entry. 
         * called by the LaravelPagination component.
         * It will fetch any remaining id's
         * @param entity the entity to fetch information for
         */
        async goTo(entity: any) { 
            // do request to find id's
            if (!entity.CustomerID) {
                this.loading = true;
                this.error = false;
                try { 
                    const response = await this.$root?.$data.API.request({
                        url: 'customerlookup/get-ids', 
                        method: 'get',
                        params: entity
                    })

                    if (response.data.CustomerID) { 
                        entity.CustomerID = response.data.CustomerID;
                    }
                    if (response.data.ServiceAgreementID && !entity.ServiceAgreementID) { 
                        entity.ServiceAgreementID = response.data.ServiceAgreementID;
                    }
                    if (response.data.ServiceTaskID && !entity.ServiceTaskID) { 
                        entity.ServiceTaskID = response.data.ServiceTaskID;
                    }
                } catch (error) {
                    this.error = true;
                    this.loading = false;
                    return;
                }
                this.error = false;
                this.loading = false;
            }
            
            this.$router.push({ name: 'customer_lookup_view', params: { id: entity.CustomerID, step: 'DebitorInfo', agreementID: entity.ServiceAgreementID, taskID: entity.ServiceTaskID } }) 
        }, 
        async getFields() { 
            this.loading = true;
            this.error = false;
            try { 
                const response = await this.$root?.$data.API.request({
                    url: 'customerlookup/search-fields', 
                    method: 'get'
                })
                this.searchableFields = response.data;
            } catch (error) {
                this.loading = false;
                this.error = true;
                return;
            }
            this.loading = false;
            this.error = false;
            
        }, 
        tableChange(table: any) {
            
            for (const tableRes of this.searchableFields) {
                tableRes.selected = (tableRes.table === table.table);
            }

 
        },

    }

});
