import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, normalizeClass as _normalizeClass, createVNode as _createVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "col" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "col" }
const _hoisted_5 = {
  key: 0,
  class: "text-warning"
}
const _hoisted_6 = {
  key: 1,
  class: "col"
}
const _hoisted_7 = {
  key: 2,
  class: "col"
}
const _hoisted_8 = {
  key: 0,
  class: "row"
}
const _hoisted_9 = { class: "col text-end" }
const _hoisted_10 = ["onClick"]
const _hoisted_11 = {
  key: 1,
  class: "my-5"
}
const _hoisted_12 = { key: 1 }
const _hoisted_13 = {
  key: 0,
  class: "col"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DateInput = _resolveComponent("DateInput")!
  const _component_TextInput = _resolveComponent("TextInput")!
  const _component_BooleanInput = _resolveComponent("BooleanInput")!
  const _component_TextareaInput = _resolveComponent("TextareaInput")!
  const _component_NumberInput = _resolveComponent("NumberInput")!
  const _component_SearchInput = _resolveComponent("SearchInput")!
  const _component_DropdownInput = _resolveComponent("DropdownInput")!
  const _component_CustomInput = _resolveComponent("CustomInput")!
  const _component_FieldDescription = _resolveComponent("FieldDescription")!
  const _component_ServiceTask = _resolveComponent("ServiceTask")!
  const _component_b_icon_trash2_fill = _resolveComponent("b-icon-trash2-fill")!

  return (_ctx.serviceContract.canBeArray)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.serviceContract.values, (value, vi) => {
          return (_openBlock(), _createElementBlock("form", {
            class: "needs-validation row",
            novalidate: "",
            key: vi
          }, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, [
                  _createElementVNode("h5", null, _toDisplayString(_ctx.serviceContract.getName(_ctx.serviceContract.currentStep)) + " " + _toDisplayString(vi + 1), 1),
                  (vi > 0)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_5, "OBS! Hver adresse bliver oprettet med egen servicekontrakt"))
                    : _createCommentVNode("", true)
                ])
              ]),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(value, (section, zi) => {
                return (_openBlock(), _createElementBlock("div", {
                  class: "row",
                  novalidate: "",
                  key: zi
                }, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(section.columns, (column, xi) => {
                    return (_openBlock(), _createElementBlock("div", {
                      class: "col mb-4",
                      key: xi
                    }, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(column.fields, (field, index) => {
                        return (_openBlock(), _createElementBlock("div", {
                          class: "row",
                          key: index
                        }, [
                          (field.type != 'serviceTask')
                            ? (_openBlock(), _createElementBlock("div", {
                                key: 0,
                                class: _normalizeClass(field.col ? field.col : 'col')
                              }, [
                                (_ctx.showField('date', field))
                                  ? (_openBlock(), _createBlock(_component_DateInput, {
                                      key: 0,
                                      disabledDates: _ctx.disabledDates,
                                      label: field.label,
                                      readonly: field.readonly,
                                      id: field.id,
                                      onUpdate: _ctx.fieldChanged,
                                      errors: field.errors,
                                      "onUpdate:errors": ($event: any) => ((field.errors) = $event),
                                      date: field.date,
                                      "onUpdate:date": ($event: any) => ((field.date) = $event),
                                      modelValue: field.value,
                                      "onUpdate:modelValue": ($event: any) => ((field.value) = $event),
                                      rules: field.rules
                                    }, null, 8, ["disabledDates", "label", "readonly", "id", "onUpdate", "errors", "onUpdate:errors", "date", "onUpdate:date", "modelValue", "onUpdate:modelValue", "rules"]))
                                  : _createCommentVNode("", true),
                                (_ctx.showField('text', field))
                                  ? (_openBlock(), _createBlock(_component_TextInput, {
                                      key: 1,
                                      label: field.label,
                                      readonly: field.readonly,
                                      id: field.id,
                                      errors: field.errors,
                                      "onUpdate:errors": ($event: any) => ((field.errors) = $event),
                                      onUpdate: _ctx.fieldChanged,
                                      modelValue: field.value,
                                      "onUpdate:modelValue": ($event: any) => ((field.value) = $event),
                                      rules: field.rules
                                    }, null, 8, ["label", "readonly", "id", "errors", "onUpdate:errors", "onUpdate", "modelValue", "onUpdate:modelValue", "rules"]))
                                  : _createCommentVNode("", true),
                                (_ctx.showField('boolean', field))
                                  ? (_openBlock(), _createBlock(_component_BooleanInput, {
                                      key: 2,
                                      label: field.label,
                                      readonly: field.readonly,
                                      id: field.id,
                                      errors: field.errors,
                                      "onUpdate:errors": ($event: any) => ((field.errors) = $event),
                                      onUpdate: _ctx.fieldChanged,
                                      modelValue: field.value,
                                      "onUpdate:modelValue": ($event: any) => ((field.value) = $event),
                                      rules: field.rules
                                    }, null, 8, ["label", "readonly", "id", "errors", "onUpdate:errors", "onUpdate", "modelValue", "onUpdate:modelValue", "rules"]))
                                  : _createCommentVNode("", true),
                                (_ctx.showField('textarea', field))
                                  ? (_openBlock(), _createBlock(_component_TextareaInput, {
                                      key: 3,
                                      label: field.label,
                                      readonly: field.readonly,
                                      id: field.id,
                                      errors: field.errors,
                                      "onUpdate:errors": ($event: any) => ((field.errors) = $event),
                                      onUpdate: _ctx.fieldChanged,
                                      modelValue: field.value,
                                      "onUpdate:modelValue": ($event: any) => ((field.value) = $event),
                                      rules: field.rules
                                    }, null, 8, ["label", "readonly", "id", "errors", "onUpdate:errors", "onUpdate", "modelValue", "onUpdate:modelValue", "rules"]))
                                  : _createCommentVNode("", true),
                                (_ctx.showField('number', field))
                                  ? (_openBlock(), _createBlock(_component_NumberInput, {
                                      key: 4,
                                      label: field.label,
                                      readonly: field.readonly,
                                      id: field.id,
                                      errors: field.errors,
                                      "onUpdate:errors": ($event: any) => ((field.errors) = $event),
                                      onUpdate: _ctx.fieldChanged,
                                      modelValue: field.value,
                                      "onUpdate:modelValue": ($event: any) => ((field.value) = $event),
                                      rules: field.rules
                                    }, null, 8, ["label", "readonly", "id", "errors", "onUpdate:errors", "onUpdate", "modelValue", "onUpdate:modelValue", "rules"]))
                                  : _createCommentVNode("", true),
                                (_ctx.showField('search', field))
                                  ? (_openBlock(), _createBlock(_component_SearchInput, {
                                      key: 5,
                                      primary: field.primary,
                                      multiple: field.multiple,
                                      exclude: field.exclude,
                                      label: field.label,
                                      onUpdate: _ctx.fieldChanged,
                                      readonly: field.readonly,
                                      values: field.values,
                                      id: field.id,
                                      errors: field.errors,
                                      "onUpdate:errors": ($event: any) => ((field.errors) = $event),
                                      modelValue: field.value,
                                      "onUpdate:modelValue": ($event: any) => ((field.value) = $event),
                                      rules: field.rules
                                    }, null, 8, ["primary", "multiple", "exclude", "label", "onUpdate", "readonly", "values", "id", "errors", "onUpdate:errors", "modelValue", "onUpdate:modelValue", "rules"]))
                                  : _createCommentVNode("", true),
                                (_ctx.showField('dropdown', field))
                                  ? (_openBlock(), _createBlock(_component_DropdownInput, {
                                      key: 6,
                                      label: field.label,
                                      readonly: field.readonly,
                                      values: field.values,
                                      id: field.id,
                                      onUpdate: _ctx.fieldChanged,
                                      errors: field.errors,
                                      "onUpdate:errors": ($event: any) => ((field.errors) = $event),
                                      modelValue: field.value,
                                      "onUpdate:modelValue": ($event: any) => ((field.value) = $event),
                                      rules: field.rules
                                    }, null, 8, ["label", "readonly", "values", "id", "onUpdate", "errors", "onUpdate:errors", "modelValue", "onUpdate:modelValue", "rules"]))
                                  : _createCommentVNode("", true),
                                (_ctx.showField('custom', field))
                                  ? (_openBlock(), _createBlock(_component_CustomInput, {
                                      key: 7,
                                      label: field.label,
                                      serviceContract: _ctx.serviceContract,
                                      id: field.id,
                                      fieldChanged: _ctx.lastUpdatedField
                                    }, null, 8, ["label", "serviceContract", "id", "fieldChanged"]))
                                  : _createCommentVNode("", true)
                              ], 2))
                            : _createCommentVNode("", true),
                          (field.description && field.type != 'serviceTask')
                            ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                                _createVNode(_component_FieldDescription, {
                                  description: field.description,
                                  field: field.id,
                                  id: field.value
                                }, null, 8, ["description", "field", "id"])
                              ]))
                            : _createCommentVNode("", true),
                          (field.type == 'serviceTask')
                            ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                                _createVNode(_component_ServiceTask, {
                                  field: field,
                                  serviceContract: _ctx.serviceContract,
                                  index: vi
                                }, null, 8, ["field", "serviceContract", "index"])
                              ]))
                            : _createCommentVNode("", true)
                        ]))
                      }), 128))
                    ]))
                  }), 128))
                ]))
              }), 128))
            ]),
            (_ctx.serviceContract.values.length > 1)
              ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                  _createElementVNode("div", _hoisted_9, [
                    _createElementVNode("button", {
                      type: "button",
                      onClick: ($event: any) => (_ctx.removeSection(vi)),
                      class: "btn btn-link"
                    }, [
                      _createVNode(_component_b_icon_trash2_fill),
                      _createTextVNode("Slet " + _toDisplayString(_ctx.serviceContract.getName(_ctx.serviceContract.currentStep)), 1)
                    ], 8, _hoisted_10)
                  ])
                ]))
              : _createCommentVNode("", true),
            (_ctx.serviceContract.values.length > 1)
              ? (_openBlock(), _createElementBlock("hr", _hoisted_11))
              : _createCommentVNode("", true)
          ]))
        }), 128))
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_12, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.serviceContract.fields, (section, zi) => {
          return (_openBlock(), _createElementBlock("form", {
            class: "needs-validation row",
            novalidate: "",
            key: zi
          }, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(section.columns, (column, xi) => {
              return (_openBlock(), _createElementBlock("div", {
                class: "col mb-4",
                key: xi
              }, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(column.fields, (field, index) => {
                  return (_openBlock(), _createElementBlock("div", {
                    class: "row",
                    key: index
                  }, [
                    _createElementVNode("div", {
                      class: _normalizeClass(field.col ? field.col : 'col')
                    }, [
                      (_ctx.showField('date', field))
                        ? (_openBlock(), _createBlock(_component_DateInput, {
                            key: 0,
                            disabledDates: _ctx.disabledDates,
                            label: field.label,
                            onUpdate: _ctx.fieldChanged,
                            readonly: field.readonly,
                            id: field.id,
                            errors: field.errors,
                            "onUpdate:errors": ($event: any) => ((field.errors) = $event),
                            date: field.date,
                            "onUpdate:date": ($event: any) => ((field.date) = $event),
                            modelValue: field.value,
                            "onUpdate:modelValue": ($event: any) => ((field.value) = $event),
                            rules: field.rules
                          }, null, 8, ["disabledDates", "label", "onUpdate", "readonly", "id", "errors", "onUpdate:errors", "date", "onUpdate:date", "modelValue", "onUpdate:modelValue", "rules"]))
                        : _createCommentVNode("", true),
                      (_ctx.showField('text', field))
                        ? (_openBlock(), _createBlock(_component_TextInput, {
                            key: 1,
                            label: field.label,
                            readonly: field.readonly,
                            onUpdate: _ctx.fieldChanged,
                            id: field.id,
                            errors: field.errors,
                            "onUpdate:errors": ($event: any) => ((field.errors) = $event),
                            modelValue: field.value,
                            "onUpdate:modelValue": ($event: any) => ((field.value) = $event),
                            rules: field.rules
                          }, null, 8, ["label", "readonly", "onUpdate", "id", "errors", "onUpdate:errors", "modelValue", "onUpdate:modelValue", "rules"]))
                        : _createCommentVNode("", true),
                      (_ctx.showField('boolean', field))
                        ? (_openBlock(), _createBlock(_component_BooleanInput, {
                            key: 2,
                            label: field.label,
                            readonly: field.readonly,
                            onUpdate: _ctx.fieldChanged,
                            id: field.id,
                            errors: field.errors,
                            "onUpdate:errors": ($event: any) => ((field.errors) = $event),
                            modelValue: field.value,
                            "onUpdate:modelValue": ($event: any) => ((field.value) = $event),
                            rules: field.rules
                          }, null, 8, ["label", "readonly", "onUpdate", "id", "errors", "onUpdate:errors", "modelValue", "onUpdate:modelValue", "rules"]))
                        : _createCommentVNode("", true),
                      (_ctx.showField('textarea', field))
                        ? (_openBlock(), _createBlock(_component_TextareaInput, {
                            key: 3,
                            label: field.label,
                            readonly: field.readonly,
                            onUpdate: _ctx.fieldChanged,
                            id: field.id,
                            errors: field.errors,
                            "onUpdate:errors": ($event: any) => ((field.errors) = $event),
                            modelValue: field.value,
                            "onUpdate:modelValue": ($event: any) => ((field.value) = $event),
                            rules: field.rules
                          }, null, 8, ["label", "readonly", "onUpdate", "id", "errors", "onUpdate:errors", "modelValue", "onUpdate:modelValue", "rules"]))
                        : _createCommentVNode("", true),
                      (_ctx.showField('number', field))
                        ? (_openBlock(), _createBlock(_component_NumberInput, {
                            key: 4,
                            label: field.label,
                            readonly: field.readonly,
                            id: field.id,
                            onUpdate: _ctx.fieldChanged,
                            errors: field.errors,
                            "onUpdate:errors": ($event: any) => ((field.errors) = $event),
                            modelValue: field.value,
                            "onUpdate:modelValue": ($event: any) => ((field.value) = $event),
                            rules: field.rules
                          }, null, 8, ["label", "readonly", "id", "onUpdate", "errors", "onUpdate:errors", "modelValue", "onUpdate:modelValue", "rules"]))
                        : _createCommentVNode("", true),
                      (_ctx.showField('search', field))
                        ? (_openBlock(), _createBlock(_component_SearchInput, {
                            key: 5,
                            primary: field.primary,
                            multiple: field.multiple,
                            onUpdate: _ctx.fieldChanged,
                            exclude: field.exclude,
                            label: field.label,
                            readonly: field.readonly,
                            values: field.values,
                            id: field.id,
                            errors: field.errors,
                            "onUpdate:errors": ($event: any) => ((field.errors) = $event),
                            modelValue: field.value,
                            "onUpdate:modelValue": ($event: any) => ((field.value) = $event),
                            rules: field.rules
                          }, null, 8, ["primary", "multiple", "onUpdate", "exclude", "label", "readonly", "values", "id", "errors", "onUpdate:errors", "modelValue", "onUpdate:modelValue", "rules"]))
                        : _createCommentVNode("", true),
                      (_ctx.showField('dropdown', field))
                        ? (_openBlock(), _createBlock(_component_DropdownInput, {
                            key: 6,
                            label: field.label,
                            readonly: field.readonly,
                            onUpdate: _ctx.fieldChanged,
                            values: field.values,
                            id: field.id,
                            errors: field.errors,
                            "onUpdate:errors": ($event: any) => ((field.errors) = $event),
                            modelValue: field.value,
                            "onUpdate:modelValue": ($event: any) => ((field.value) = $event),
                            rules: field.rules
                          }, null, 8, ["label", "readonly", "onUpdate", "values", "id", "errors", "onUpdate:errors", "modelValue", "onUpdate:modelValue", "rules"]))
                        : _createCommentVNode("", true),
                      (_ctx.showField('custom', field))
                        ? (_openBlock(), _createBlock(_component_CustomInput, {
                            key: 7,
                            label: field.label,
                            serviceContract: _ctx.serviceContract,
                            id: field.id,
                            fieldChanged: _ctx.lastUpdatedField
                          }, null, 8, ["label", "serviceContract", "id", "fieldChanged"]))
                        : _createCommentVNode("", true)
                    ], 2),
                    (field.description)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                          _createVNode(_component_FieldDescription, {
                            description: field.description,
                            field: field.id,
                            id: field.value
                          }, null, 8, ["description", "field", "id"])
                        ]))
                      : _createCommentVNode("", true)
                  ]))
                }), 128))
              ]))
            }), 128))
          ]))
        }), 128))
      ]))
}