
import { defineComponent } from 'vue';

import { Field } from '@/modules/CreateServiceContract/helpers/Fields.interface';
import FieldsComponent from '@/components/layout/Fields.vue'
export default defineComponent({
    components: {
        FieldsComponent,
    },
    props: {
        id: {
            type: Number, 
            required: true
        }, 
        agreementID: {
            type: String, 
            required: false
        }, 
        taskID: {
            type: String, 
            required: false
        }
        
    },
    mounted() { 
        this.getFields();
    },
    watch: {
        async config() { 
            await this.getFields();
        }
    },
    data: function () {
        return {
            loading: true, 
            fields: [] as Field[][]
        }
    }, 
    computed: {
        config: function () {
            let url: string = "customerlookup/fields/articles";
            if (this.agreementID) {
                url += "/" + this.agreementID; 
                if (this.taskID) {
                    url += "/" + this.taskID;
                }
            }
            return {
                url,
                method: 'get',
            }
        }
    },
    methods: {
        async getFields() {
            this.loading = true;
            try {
                const response = await this.$root?.$data.API.request(this.config)
                this.fields = response.data;
            } catch (error) {
                this.loading = true;
            }
            this.loading = false;

        },
        async fieldUpdated(field: any) { 
        }
    }
});

