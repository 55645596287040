

import { defineComponent } from 'vue';
import Tabs from '@/components/widgets/Tabs.vue'
import ContractInfo from '../components/ContractInfo.vue'
import DebitorInfo from '../components/DebitorInfo.vue'
import DeliveryAddress from '../components/DeliveryAddress.vue'
import ServiceTasks from '../components/ServiceTasks.vue'
import ArticleList from '../components/ArticleList.vue'

export default defineComponent({
    components: {
        Tabs,
        ContractInfo,
        DebitorInfo,
        DeliveryAddress,
        ServiceTasks,
        ArticleList
    },
    beforeRouteUpdate(to, from) {
        this.emitter.emit("route-updated", { to, from })
    },
    data: function () {
        return {
            steps: {
                "DebitorInfo": "Debitor oplysninger",
                "ContractInfo": "Kontrakt information",
                "DeliveryAddress": "Serviceopgaveinformation & leveringsaddresse",
                "ServiceTasks": "Serviceopgaver",
                "ArticleList": "Udstyrsliste"
            },
        };
    },
    watch: {
    },
    computed: {
        /**
         * CustomerID of the current page
         */
        id: function () {
            const id = this.$router.currentRoute.value.params.id;
            return Number(id);
        },
        /**
         * The current tab/step of the elements
         */
        step: function (): string {
            const step = this.$router.currentRoute.value.params?.step;
            if (!step) return "DebitorInfo";
            return String(step);
        },
        /**
         * The agreementID 
         * Will only be present after one of the pages where it can be selected one have been visited, 
         * but ar carried along between the tabs in the route
         */
        agreementID: function (): string {
            const params = this.$router.currentRoute.value.params;
            const agreementID = params?.agreementID;
            if (Array.isArray(agreementID)) {
                return agreementID[0];
            }
            return agreementID;
        },
        /**
         * The taskID of the tasks that have been selected. 
         * Only pressent after it have been selected. The Selected one is carried between tabs by the routes. 
         */
        taskID: function (): string {
            const params = this.$router.currentRoute.value.params;
            const taskID = params?.taskID;
            if (Array.isArray(taskID)) {
                return taskID[0];
            }
            return taskID;
        },
    },
    methods: {
        /**
         * Change the tab component to match the tab
         * @param step The step to go to
         */
        goToStep: function (step: string) {
            this.$router.push({ name: 'customer_lookup_view', params: { id: this.id, step: step, agreementID: this.agreementID, taskID: this.taskID } })
        },
        /**
         * Translate the current step to the component name; 
         */
        getComponentName: function () {
            const steps = ["DebitorInfo", "ContractInfo", "DeliveryAddress", "ServiceTasks", "ArticleList"];

            if (steps.includes(this.step)) return this.step;
            return "DebitorInfo";
        },

        /**
         * Changes the route to the search. 
         */
        backToSearch() {
            const fields = JSON.stringify(this?.$root?.$data?.lastSearch?.params?.fields);
            const query = this?.$root?.$data?.lastSearch?.params?.query;
            this.$router.push({ name: 'customer_lookup_search', params: { query, fields } });
        }
    }

});
