import { AxiosInstance } from "axios";
import { CustomerID, CountryID, CustomerTypeID,
         CompanyName, Address, City, ZipCode, Region,
         ContactName, ContactEMail, ContactPhoneNo, ChangedDate,
         ChangedBy, CreatedDate, CreatedBy, Deleted,
         BusinessChainID, IndustryID, CompanyPhone,
         EAN, CVR, InvoiceEmail, CustomerDTO} from './types/customer.type';
import defaultClass from './defaultClass';
import { urls } from './types/default.type';

export default class Customer extends defaultClass {
    protected _model: CustomerDTO;
    protected _primaryKey: string;
    protected _urls: urls;

    constructor(api: AxiosInstance, customerId: CustomerID = null) {
        super(api);
        this._model = {
            CustomerID: null,
            CountryID: null,
            CustomerTypeID: null,
            CompanyName: null,
            Address: null,
            Address2: null,
            City: null,
            ZipCode: null,
            Region: null,
            ContactName: null,
            ContactEMail: null,
            ContactPhoneNo: null,
            ChangedDate: null,
            ChangedBy: null,
            CreatedDate: null,
            CreatedBy: null,
            Deleted: null,
            BusinessChainID: null,
            IndustryID: null,
            CompanyPhone: null,
            EAN: null,
            CVR: null,
            InvoiceEmail: null,
        }

        this._primaryKey = 'CustomerID';

        this._urls = {
            get: 'customer/{CustomerID}',
            post: 'customer',
            delete: 'customer/{CustomerID}',
            put: 'customer/{CustomerID}',
        };

        if (customerId) {
            this._model.CustomerID = customerId;
            this._get();
        }
    }

    

    private _fill(customer: CustomerDTO) {
        this._model = customer;
    }

    get CustomerID(): CustomerID {
        return this._model.CustomerID;
    }

    get CountryID(): CountryID {
        return this._model.CountryID;
    }

    get CustomerTypeID(): CustomerTypeID {
        return this._model.CustomerTypeID;
    }

    get CompanyName(): CompanyName {
        return this._model.CompanyName;
    }

    get Address(): Address {
        return this._model.Address;
    }

    get Address2(): Address {
        return this._model.Address2;
    }

    get City(): City {
        return this._model.City;
    }

    get ZipCode(): ZipCode {
        return this._model.ZipCode;
    }

    get Region(): Region {
        return this._model.Region;
    }

    get ContactName(): ContactName {
        return this._model.ContactName;
    }

    get ContactEMail(): ContactEMail {
        return this._model.ContactEMail;
    }

    get ContactPhoneNo(): ContactPhoneNo {
        return this._model.ContactPhoneNo;
    }

    get ChangedDate(): ChangedDate {
        return this._model.ChangedDate;
    }

    get ChangedBy(): ChangedBy {
        return this._model.ChangedBy;
    }

    get CreatedDate(): CreatedDate {
        return this._model.CreatedDate;
    }

    get CreatedBy(): CreatedBy {
        return this._model.CreatedBy;
    }

    get Deleted(): Deleted {
        return this._model.Deleted;
    }

    get BusinessChainID(): BusinessChainID {
        return this._model.BusinessChainID;
    }

    get IndustryID(): IndustryID {
        return this._model.IndustryID;
    }

    get CompanyPhone(): CompanyPhone {
        return this._model.CompanyPhone;
    }

    get EAN(): EAN {
        return this._model.EAN;
    }

    get CVR(): CVR {
        return this._model.CVR;
    }

    get InvoiceEmail():InvoiceEmail {
        return this._model.InvoiceEmail;
    }

    set CustomerID(value: CustomerID) {
        this._model.CustomerID = value;
    }

    set CountryID(value: CountryID) {
        this._model.CountryID = value;
    }

    set CustomerTypeID(value: CustomerTypeID) {
        this._model.CustomerTypeID = value;
    }

    set CompanyName(value: CompanyName) {
        this._model.CompanyName = value;
    }

    set address(value: Address) {
        this._model.Address = value;
    }

    set address2(value: Address) {
        this._model.Address2 = value;
    }

    set City(value: City) {
        this._model.City = value;
    }

    set ZipCode(value: ZipCode) {
        this._model.ZipCode = value;
    }

    set Region(value: Region) {
        this._model.Region = value;
    }

    set ContactName(value: ContactName) {
        this._model.ContactName = value;
    }

    set ContactEMail(value: ContactEMail) {
        this._model.ContactEMail = value;
    }

    set ContactPhoneNo(value: ContactPhoneNo) {
        this._model.ContactPhoneNo = value;
    }

    set ChangedDate(value: ChangedDate) {
        this._model.ChangedDate = value;
    }

    set ChangedBy(value: ChangedBy) {
        this._model.ChangedBy = value;
    }

    set CreatedDate(value: CreatedDate) {
        this._model.CreatedDate = value;
    }

    set CreatedBy(value: CreatedBy) {
        this._model.CreatedBy = value;
    }

    set Deleted(value: Deleted) {
        this._model.Deleted = value;
    }

    set BusinessChainID(value: BusinessChainID) {
        this._model.BusinessChainID = value;
    }

    set IndustryID(value: IndustryID) {
        this._model.IndustryID = value;
    }

    set CompanyPhone(value: CompanyPhone) {
        this._model.CompanyPhone = value;
    }

    set EAN(value: EAN) {
        this._model.EAN = value;
    }

    set CVR(value: CVR) {
        this._model.CVR = value;
    }

    set InvoiceEmail(value: InvoiceEmail) {
        this._model.InvoiceEmail = value;
    }

}