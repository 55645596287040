import { AxiosInstance, AxiosRequestConfig } from "axios";
import { Fields, Section, Field, Step, Column, priceCache } from "./Fields.interface"
import Customer from '@/class/customer';
import ServiceAgreement from '@/class/serviceAgreement';
import ServiceAgreementItem from '@/class/serviceAgreementItem';
import DeliveryAddress from '@/class/deliveryAddress';
import { CustomerID } from '@/class/types/customer.type';
import moment from 'moment';

export default class ServiceContract {
    _steps: string[];
    _currentStep: number;
    _maxStep: number;
    _api: AxiosInstance;
    _router: any;
    _fields: Fields;
    _existingCustomer: boolean;
    _route: any;
    _serviceTaskFields: Field[];
    _ready: boolean;
    _customer: Customer;
    _hourPrice: number;
    _contractSaved: boolean;
    _errors: boolean = false;
    _nonDataTypes: string[] = ['new_column', 'new_row', 'new_section'];
    _priceCache: priceCache = {};
    _contractSavedIds: string[] = [];

    constructor(router: any, route: any, api: AxiosInstance) {
        this._ready = false;
        this._steps = this._buildSteps();
        this._router = router;
        this._route = route;
        this._api = api;
        this._existingCustomer = false;
        this._customer = new Customer(api);
        this._hourPrice = -1;
        this._contractSaved = false;
        this._contractSavedIds = [];
    
        if (route.params.stepName) {
            this._currentStep = this._steps.indexOf(route.params.stepName);
            this._maxStep = this._currentStep;
        } else {
            this._currentStep = 0;
            this._maxStep = 0;
        }

        this._fields = {
            steps: []
        }

        this._serviceTaskFields = [];

        this._initSteps();
    }

    private _reload() {
        if (this._currentStep > 0) {
            this._router.push('/OpretServiceKontrakt/' + this._steps[0]);
        }
        this._currentStep = 0;
        this._maxStep = 0;
        this._fields = {
            steps: []
        }

        this._initSteps();

    }

    private _buildSteps(): string[] {
        return [
            'Kundeoplysninger',
            'KontraktInformation',
            'Addresse',
            'Årspræmie',
            'Bemærkninger',
            'Opsummering',
            'Underskift'
        ]
    }

    private async _initSteps(): Promise<void> {
        const _buildContractInformation = this._buildContractInformation();
        const _buildCustomerInformation = this._buildCustomerInformation();
        const _buildAddressInformation = this._buildAddressInformation();
        const _buildYearlyServiceFeeInformation = this._buildYearlyServiceFeeInformation();
        const _buildRemarksInformation = this._buildRemarksInformation();

        const _buildServiceTaskFields = this._buildServiceTaskFields();

        const _getHourPrice = this._getHourPrice();


        await Promise.all([
            _buildContractInformation,
            _buildCustomerInformation,
            _buildAddressInformation,
            _buildYearlyServiceFeeInformation,
            _buildRemarksInformation,
            _buildServiceTaskFields,
            _getHourPrice
        ]);

        this._ready = true;
    }

    private async _buildCustomerInformation(): Promise<void> {
        return new Promise((resolve) => {
        const config = {
            url: 'servicecontract/fields/customer',
            method: 'get',
            params: {
                new: !this._existingCustomer,
                customerId: this._customer.CustomerID
            }
        }
        this._api.request(config)
            .then((response) => {
                this._parseFields(response.data, this._steps.indexOf('Kundeoplysninger'));
            })
            .catch((error) => {
                console.error(error);
                this._errors = true;
            })
            .finally(() => {
                resolve();
            });
        });
    }

    public async downloadContract(): Promise<void> {
        const config: AxiosRequestConfig<any> = {
            url: 'service-agreement/download/' + this._contractSavedIds[0],
            method: 'get',
            responseType: 'blob'
        }
        const pdf = await this._api.request(config)
        const blob = new Blob([pdf.data], { type: 'application/pdf' })
        const link = document.createElement('a')
        link.href = URL.createObjectURL(blob)
        link.download = this._contractSavedIds[0] + '.pdf';
        link.click()
        URL.revokeObjectURL(link.href)
    }

    private _buildContractInformation(): Promise<void> {
        return new Promise((resolve) => {
            const config = {
                url: 'servicecontract/fields/contract',
                method: 'get'
            }
            this._api.request(config)
                .then((response) => {
                    this._parseFields(response.data, this._steps.indexOf('KontraktInformation'));
                })
                .catch((error) => {
                    console.error(error);
                    this._errors = true;
                })
                .finally(() => {
                    resolve();
                });
        });
    }

    private _buildAddressInformation(): Promise<void> {
        return new Promise((resolve) => {
            const config = {
                url: 'servicecontract/fields/address',
                method: 'get',
            }
            this._api.request(config)
                .then((response) => {
                    const canBeArray = true;
                    this._parseFields(response.data, this._steps.indexOf('Addresse'), canBeArray);
                })
                .catch((error) => {
                    console.error(error);
                    this._errors = true;
                })
                .finally(() => {
                    resolve();
                });
        });
    }

    private _buildYearlyServiceFeeInformation(): Promise<void> {
        return new Promise((resolve) => {
            const config = {
                url: 'servicecontract/fields/service-fee',
                method: 'get',
            }
            this._api.request(config)
                .then((response) => {
                    this._parseFields(response.data, this._steps.indexOf('Årspræmie'));
                })
                .catch((error) => {
                    console.error(error);
                    this._errors = true;
                })
                .finally(() => {
                    resolve();
                });
        });
    }

    private _buildRemarksInformation(): Promise<void> {
        return new Promise((resolve) => {
            const config = {
                url: 'servicecontract/fields/remarks',
                method: 'get',
            }
            this._api.request(config)
                .then((response) => {
                    this._parseFields(response.data, this._steps.indexOf('Bemærkninger'));
                })
                .catch((error) => {
                    console.error(error);
                    this._errors = true;
                })
                .finally(() => {
                    resolve();
                });
        });
    }

    private _buildServiceTaskFields(): Promise<void> {
        return new Promise((resolve) => {
            const config = {
                url: 'servicecontract/fields/address/service-tasks',
                method: 'get',
            }
            this._api.request(config)
                .then((response) => {
                    this._serviceTaskFields = response.data;
                })
                .catch((error) => {
                    console.error(error);
                    this._errors = true;
                })
                .finally(() => {
                    resolve();
                });
        });
    }

    private _getHourPrice(): Promise<void> {
        return new Promise((resolve) => {
            const config = {
                url: 'service-task/hour-price',
                method: 'get',
            }
            this._api.request(config)
                .then((response) => {
                    this._hourPrice = parseFloat(response.data);
                })
                .catch((error) => {
                    console.error(error);
                    this._errors = true;
                })
                .finally(() => {
                    resolve();
                });
        });
    }

    private _parseFields(fields: Field[], index: number, canBeArray: boolean = false): void {
        
        let column = 0;
        let section = 0;
        this._fields.steps[index] = {
            canBeArray: canBeArray,
            sections: [
                {
                    columns: [
                        {
                            fields: [],
                        }
                    ],
                }
            ],
            values: []
        }

        for (let field in fields) {
            const fieldObject = <Field> fields[field];
            if (fieldObject.type == 'new_section') {
                column = 0;
                section++;
                this._fields.steps[index].sections[section] = {
                    columns: [
                        {
                            fields: [],
                        }
                    ],
                }
                continue;
            }
            if (fieldObject.type == 'new_column') {
                column++;
                this._fields.steps[index].sections[section].columns[column] =  {
                    fields: [],
                }
                continue;
            }

            fieldObject.errors = false;
            if (fieldObject.type == 'date') {
                fieldObject.date = this._getNextValidDate();
            }
            if (fieldObject.type == 'serviceTask') {
                fieldObject.value = [];
            }
            this._fields.steps[index].sections[section].columns[column].fields.push(fieldObject);
        }
        if (canBeArray) {
            this.addSection(index);
        }
    }

    private _saveValues(): void {
        const values: any = {
            values: {}
        };
        for (let step in this._fields.steps) {
            if (this._fields.steps[step].canBeArray) {
                this._fields.steps[step]?.values?.forEach((value, vi) => {
                    for (let step1 in value) {
                        value[step1].columns.forEach((column: Column) => {
                            column.fields.forEach((field) => {
                                if (!this._nonDataTypes.includes(field.type)) {
                                    if (field.id)
                                    {
                                        if (!values['values'][vi]) {
                                            values['values'][vi] = {};
                                        }
                                        values['values'][vi][field.id] = field.value;
                                    } else {
                                        if (field.type == 'serviceTask') {
                                            values['values'][vi]['serviceTask'] = field.value;
                                        }
                                    }
                                }
                            })
                        })
                    }
                });
            } else {
                this._fields.steps[step].sections.forEach((section) => {
                    section.columns.forEach((column) => {
                        column.fields.forEach((field) => {
                            if (!this._nonDataTypes.includes(field.type)) {
                                if (field.id)
                                {
                                    values[field.id] = field.value;
                                }
                            }
                        })
                    })
                })
            }
        };

        localStorage.setItem('serviceContractFieldValues', JSON.stringify(values));
        if (this._existingCustomer) {
            localStorage.setItem('existingCustomer', 'true');
            localStorage.setItem('customer', this._customer?.CustomerID?.toString() || '');
        }
    }

    private async _restore(): Promise<void> {
        const values = JSON.parse(localStorage.getItem('serviceContractFieldValues') || '{}');
        const existingCustomer = JSON.parse(localStorage.getItem('existingCustomer') || 'false');
        if (existingCustomer == true) {
            this._existingCustomer = true;
            this._customer.CustomerID = parseInt(localStorage.getItem('customer') || '');
            this._customer.get();
            await this._initSteps();
        }

        for (let step in this._fields.steps) {
            if (this._fields.steps[step].canBeArray) {
                const sectionCount = Object.keys(values.values).length;
                const sectionExists = this._fields.steps[step].values?.length ?? 0;
                for (let i = sectionExists; i < sectionCount; i++) {
                    this.addSection(parseInt(step));
                }
                this._fields.steps[step]?.values?.forEach((value, vi) => {
                    for (let step1 in value) {
                        value[step1].columns.forEach((column: Column) => {
                            column.fields.forEach((field) => {
                                if (!this._nonDataTypes.includes(field.type)) {
                                    if (field.id)
                                    {
                                        field.value = values['values'][vi][field.id];
                                    } else {
                                        if (field.type == 'serviceTask') {
                                            field.value = values['values'][vi]['serviceTask'];
                                        }
                                    }
                                }
                            })
                        })
                    }
                });
            } else {
                this._fields.steps[step].sections.forEach((section, sectionIndex) => {
                    this._fields.steps[step].sections[sectionIndex].columns.forEach((column, columnIndex) => {
                        column.fields.forEach((field, fieldIndex) => {
                            if (field.type != 'new_column') {
                                if (field.id)
                                {
                                    this._fields.steps[step].sections[sectionIndex].columns[columnIndex].fields[fieldIndex].value = values[field.id];
                                }
                            }
                        })
                    })
                })
            }
        };
        this._updatePrice();
    }

    private async _updatePrice(): Promise<void> {

        let totalAnnuallyPrice = 0;
        let serviceTaskTotal = 0;
        const values = this._fields?.steps[this._steps.indexOf('Addresse')].values ?? [];
        for (let value in values) {
            for (let step1 in values[value]) {
                for (let column in values[value][step1].columns) {
                    for (let fieldId in values[value][step1].columns[column].fields) {
                        const field = values[value][step1].columns[column].fields[fieldId];

                        if (field.type == 'serviceTask') {
                            for (let serviceTask in field.value) {
                                serviceTaskTotal += parseFloat(field.value[serviceTask].SalesPrice);
                            }
                        }
                    }
                }
            }
        }

        totalAnnuallyPrice += serviceTaskTotal;

        const vatPrice = totalAnnuallyPrice*1.25-totalAnnuallyPrice;
        const priceIncVat = totalAnnuallyPrice*1.25;

        const discountValue = this.getFieldValue('ServiceAgreement.DiscountAmount', this._steps.indexOf('Årspræmie'));
        const discount = (discountValue && discountValue != '') ? parseInt(discountValue) : 0;

        const calculatedPrice = totalAnnuallyPrice + discount;

        const index = this._steps.indexOf('Årspræmie');
        this._fields.steps[index].sections.forEach((section) => {
            section.columns.forEach((column) => {
                column.fields.forEach((field) => {
                    if (field.id == "ServiceAgreement.CalculatedPrice") {
                        field.value = calculatedPrice.toString();
                    }
                    if (field.id == "ServiceAgreement.Vat") {
                        field.value = vatPrice.toString();
                    }
                    if (field.id == "ServiceAgreement.YearlyServiceFeeInclVat") {
                        field.value = priceIncVat.toString();
                    }
                    if (field.id == "ServiceAgreement.YearlyServiceFee") {
                        field.value = totalAnnuallyPrice.toString();
                    }
                });
            });
        });


    }

    private _getNextValidDate(): Date {
        const date = new Date();
        if (date.getDay() > 15) {
            date.setMonth(date.getMonth() + 2);
        } else {
            date.setMonth(date.getMonth() + 1);
        }
        date.setDate(1);
        return date;
    }

    private async _save(): Promise<void> {
        const serviceAgreement = this._buildServiceAgreement();
        if (!this._existingCustomer) {
            await this._customer.save();
            serviceAgreement.CustomerID = this._customer.CustomerID;
            const response: any = await serviceAgreement.save();
            this._contractSavedIds = response;
        } else {
            serviceAgreement.CustomerID = this._customer.CustomerID;
            const response: any = await serviceAgreement.save();
            this._contractSavedIds = response;
        }
    }

    private _buildServiceAgreement(): ServiceAgreement {
        const serviceAgreement: ServiceAgreement = new ServiceAgreement(this._api);
        console.log(this._fields.steps);
        for (let step in this._fields.steps) {
            if (this._fields.steps[step].canBeArray) {
                this._fields.steps[step]?.values?.forEach((value, vi) => {
                    const deliveryAddress: DeliveryAddress = new DeliveryAddress(this._api);;
                    for (let step1 in value) {
                        value[step1].columns.forEach((column: Column) => {
                            column.fields.forEach((field) => {
                                if (!this._nonDataTypes.includes(field.type)) {
                                    if (field.id)
                                    {
                                        const fieldId = field.id.split('.');
                                        const type: string = fieldId[0];
                                        const id: string = fieldId[1];
                                        switch (type) {
                                            case 'ServiceAgreement':
                                                serviceAgreement.set(id, field.value);
                                                break;
                                            case 'Customer':
                                                this._customer.set(id, field.value);
                                                break;
                                            case 'DeliveryAddress':
                                                deliveryAddress.set(id, field.value);
                                                break;
                                        
                                            default:
                                                console.log('Unknown', type, id);
                                                break;
                                        }
                                    }
                                    if(field.type == 'serviceTask') {
                                        for (let serviceTask of (field.value as Array<any>)) {
                                            const item = new ServiceAgreementItem(this._api)
                                            item.fill(serviceTask);
                                            serviceAgreement.ServiceAgreementItem = item.toArray();
                                        }
                                    }
                                }
                            })
                        })
                    }
                    serviceAgreement.DeliveryAddress = deliveryAddress.toArray();
                });
            } else {
                this._fields.steps[step].sections.forEach((section) => {
                    section.columns.forEach((column) => {
                        column.fields.forEach((field) => {
                            if (!this._nonDataTypes.includes(field.type)) {
                                if (field.id)
                                {
                                    const fieldId = field.id.split('.');
                                    const type: string = fieldId[0];
                                    const id: string = fieldId[1];
                                    const id2: string = fieldId[2] ?? '';
                                    switch (type) {
                                        case 'ServiceAgreement':
                                            serviceAgreement.set(id, field.value);
                                            break;
                                        case 'Customer':
                                            this._customer.set(id, field.value);
                                            break;
                                        case 'Debtor':
                                            if (id == 'List' && id2 == 'ID') {
                                                serviceAgreement.set('changeType', field.value);
                                            }

                                            if (id == 'SK' && id2 == 'Addon') {
                                                serviceAgreement.set('fromServiceAgreement', field.value);
                                            }

                                            break;
                                        default:
                                            console.log('Unknown', type, id);
                                            break;
                                    }
                                }
                            }
                        })
                    })
                })
            }
        };
        return serviceAgreement;
    }

    getName(step: number): string {
        return this._steps[step];
    }

    addSection(index: number | undefined = undefined) {
        if (!index) {
            index = this.currentStep;
        }

        if (this._fields.steps[index]?.canBeArray ?? false) {
            const clone = JSON.parse(JSON.stringify(this._fields.steps[index].sections));
            this._fields.steps[index]?.values?.push(clone);
        }
    }

    removeSection(index: number) {
        if (this._fields.steps[this.currentStep]?.canBeArray ?? false) {
            this._fields.steps[this.currentStep].values?.splice(index, 1);
        }
    }

    async nextStep(): Promise<void> {
        if (this._currentStep < this._steps.length - 1) {
            this._currentStep++;
            this._maxStep = this._currentStep;
            this._router.push('/OpretServiceKontrakt/' + this._steps[this._currentStep]);
            await this._updatePrice();
            this._saveValues();
        } else {
            await this._save()
            this._contractSaved = true;
            this.destroy();
        }
        return;
    }

    async previousStep(): Promise<void> {
        return new Promise((resolve) => {
            if (this._currentStep > 0) {
                this._currentStep--;
                this._router.push('/OpretServiceKontrakt/' + this._steps[this._currentStep]);
                this._updatePrice();
                this._saveValues();
            }
            resolve();
        });
    }

    getFieldValue(fieldId: string, index: number | undefined = undefined): string {
        if (!index && index != 0) {
            index = this.currentStep;
        }
        let found: string = '';

        if (index == 99) {
            for (let step in this._fields.steps) {
                found = this.searchFieldsForValue(fieldId, parseInt(step));
                if (found != '') {
                    break;
                }
            }
        } else {
            found = this.searchFieldsForValue(fieldId, index);
        }
        
        return found;
    }

    searchFieldsForValue(fieldId: string, index: number): string {
        let found: string = '';

        this._fields.steps[index].sections.forEach((section) => {
            section.columns.forEach((column) => {
                column.fields.forEach((field) => {
                    if (field.id == fieldId) {
                        found = field.value?.toString() ?? '';
                        return;
                    }
                });
            });
        });
        
        return found;
    }

    isCurrentStep(step: String): boolean {
        return this._steps[this._currentStep] == step;
    }

    restore(): void {
        this._restore();
    }

    destroy(): void {
        localStorage.removeItem('serviceContractFieldValues');
        localStorage.removeItem('existingCustomer');
        localStorage.removeItem('customer');
        this._reload();
    }

    autoFill() {
        if (this._fields.steps[this.currentStep].canBeArray) {
            this._fields?.steps[this.currentStep].values?.forEach((value) => {
                for (let i in value) {
                    this._fillSection(value[i]);
                };
            });
            return; 
        }

        this._fields.steps[this.currentStep].sections.forEach((section) => {
            this._fillSection(section);
        });
    }

    calculateDiscount() {
        this._updatePrice();
    }

    private _fillSection(section: Section): void {
        section.columns.forEach((column) => {
            column.fields.forEach((field) => {
                if (field.value != "" && field.value != null) {
                    return;
                }

                if (field.type == 'text') {
                    field.value = 'Autofill text: '+field.label;
                }
                if (field.type == 'number') {
                    field.value = '1';
                }
                //if (field.type == 'date') {
                //    field.value = '2021-01-01';
                //}

                if (field.type == 'dropdown') {
                    field.value = "1";
                }

                if (field.type == 'boolean') {
                    field.value = '1';
                }

                if (field.type == 'email' || field?.label?.toLowerCase().includes('mail')) {
                    field.value = 'test@email.com';
                }
            });
        });
    }

    get hasChanges(): boolean {

        let changes = false;
        this._fields.steps[this.currentStep].sections.forEach((section) => {
            section.columns.forEach((column) => {
                column.fields.forEach((field) => {
                    if (field.value) {
                        changes = true;
                        return;
                    }
                });
                if (changes) return;
            });
            if (changes) return;
        });
        return changes;
    }

    get hasFields(): boolean {
        return this._fields.steps[this.currentStep] && this._fields.steps[this.currentStep].sections.length > 0;
    }

    get restorePossible(): boolean {
        return localStorage.getItem('serviceContractFieldValues') != null;
    }

    get nextStepName(): string {
        if (this._currentStep < this._steps.length - 1) {
            return this._steps[this._currentStep + 1];
        }
        return 'Gem servicekontrakt';
    }

    get previousStepName(): string {
        if (this._currentStep > 0) {
            return this._steps[this._currentStep - 1];
        }
        return 'Start forfra';
    }

    get canBeArray(): boolean {
        return this._fields.steps[this.currentStep].canBeArray ?? false;
    }

    get fields(): Section[] {
        return this._fields.steps[this.currentStep].sections;
    }

    get values(): any[] {
        return this._fields.steps[this.currentStep]?.values || [];
    }

    get allFields(): Step[] {
        return this._fields.steps;
    }

    get ready(): boolean {
        return this._ready;
    }

    get errors(): boolean {
        return this._errors;
    }

    get steps(): string[] {
        return this._steps;
    }

    get maxStep(): number {
        return this._maxStep;
    }

    get totalSteps(): number {
        return this._steps.length;
    }

    get currentStep(): number {
        return this._currentStep;
    }

    get serviceTaskFields(): Field[] {
        return this._serviceTaskFields;
    }

    set currentStep(step: number) {
        this._currentStep = step;
        this._router.push('/OpretServiceKontrakt/' + this._steps[this._currentStep]);
    }

    get existingCustomer(): boolean {
        return this._existingCustomer;
    }

    set customer(customerId: string) {
        this._existingCustomer = true;
        this._customer.CustomerID = parseInt(customerId) as CustomerID;
        this._customer.get();
        this._reload();
    }

    get hourPrice(): number {
        return this._hourPrice;
    }

    get contractSaved(): boolean {
        return this._contractSaved;
    }

    set contractSaved(value: any) {
        this._contractSaved = false;
    }

    get savedContractIds(): string[] {
        return this._contractSavedIds;
    }
}