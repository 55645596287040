import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "mb-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Datepicker = _resolveComponent("Datepicker")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Datepicker, {
      modelValue: _ctx.value,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.value) = $event)),
      id: _ctx.id,
      showLabel: _ctx.showLabel,
      compact: _ctx.compact,
      readonly: _ctx.readonly,
      disabledDates: _ctx.disabledDates,
      label: _ctx.label
    }, null, 8, ["modelValue", "id", "showLabel", "compact", "readonly", "disabledDates", "label"])
  ]))
}