
    import { defineComponent } from 'vue';
    export default defineComponent({
        props: [
            'steps',// object with title as key
            'currentStep', // the current selected step
        ],
        data () {
            return {
            }
        },
        computed: {
        }
    });
