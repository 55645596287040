import { msalInstance } from "@/authConfig";

export const hasRole = (role: string): boolean => {
    if (role === null) {
        return true;
    }
    const account = msalInstance.getAllAccounts()[0];
    const roles: any = account?.idTokenClaims?.roles ?? [];

    if (roles.includes(role)) {
        return true;
    }

    if (roles.includes('admin')) {
        return true;
    }

    return false;
}