import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3e014124"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "row mb-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.appList, (data, index) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "col-auto mb-3",
        key: index
      }, [
        _createVNode(_component_router_link, {
          to: _ctx.isCurrentPage(data.name)? '' : data.link,
          onClick: _ctx.closeAppMenu
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", {
              class: _normalizeClass(["appItem", {'appItemSmall': _ctx.smallText, 'selected': _ctx.isCurrentPage(data.name)}])
            }, [
              (_openBlock(), _createBlock(_resolveDynamicComponent('b-icon-'+data.icon), { class: "appIcon" })),
              _createTextVNode(" " + _toDisplayString(data.title), 1)
            ], 2)
          ]),
          _: 2
        }, 1032, ["to", "onClick"])
      ]))
    }), 128))
  ]))
}