import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col-1" }
const _hoisted_3 = { class: "col" }
const _hoisted_4 = { class: "row" }
const _hoisted_5 = { class: "col" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_b_icon_arrow_left = _resolveComponent("b-icon-arrow-left")!
  const _component_Tabs = _resolveComponent("Tabs")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("button", {
          type: "button",
          class: "btn btn-outline-secondary",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.backToSearch && _ctx.backToSearch(...args)))
        }, [
          _createVNode(_component_b_icon_arrow_left),
          _createTextVNode(" Tilbage til søgning ")
        ])
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_Tabs, {
          steps: _ctx.steps,
          currentStep: _ctx.step,
          onStepClicked: _ctx.goToStep
        }, null, 8, ["steps", "currentStep", "onStepClicked"])
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.getComponentName()), {
          id: _ctx.id,
          agreementID: _ctx.agreementID,
          taskID: _ctx.taskID
        }, null, 8, ["id", "agreementID", "taskID"]))
      ])
    ])
  ], 64))
}