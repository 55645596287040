
import { PropType, defineComponent } from 'vue';
import LoadJobInfoModal from './LoadJobInfoModal.vue';
export default defineComponent({
    // emit an event depending on what the user clicks
    emits: [
        'click:loadCustomer',
        'click:loadAddress',
    ],
    components: {
        LoadJobInfoModal
    },
    async mounted() {
    
    },
    props: {
        CustomerID: {
            type: Number,
            default: null
        },
    },
    data() {
        return {
            displayAddressModal: false,
        }
    },
    methods: {
        loadCustomer() { 
            this.$emit('click:loadCustomer');
            // Todo: action is missing
        },

        loadAddress() { 
            this.displayAddressModal = true;
        },
        closeAddressModal() {
            this.displayAddressModal = false;
        },
        selectAddress(address: any) {
            this.$emit('click:loadAddress', address);
            this.displayAddressModal = false;
        },

    },
});
