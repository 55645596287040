
import { PropType, defineComponent } from 'vue';
import LoadCustomerModal from './LoadCustomerModal.vue';
import LoadContractModal from './LoadContractModal.vue';
export default defineComponent({
    // emit an event depending on what the user clicks
    emits: [
        'click:loadCustomer',
        'click:loadContact',
    ],
    components: {
        LoadCustomerModal,
        LoadContractModal
},
    async mounted() {
    
    },
    props: {
    },
    data() {
        return {
            displayCustomerModal: false,
            displayContractModal: false,
        }
    },
    methods: {
        loadCustomer() { 
            this.displayCustomerModal = true;
        },
        closeCustomerModal() {
            this.displayCustomerModal = false;
        },
        selectCustomer(customer: any) {
            console.log(customer);
            this.$emit('click:loadCustomer', customer);
            this.displayCustomerModal = false;
        },

        loadContact() { 
            this.displayContractModal = true;
        },
        closeContractModal() {
            this.displayContractModal = false;
        },
        selectContract(contract: any) {
            console.log(contract);
            this.$emit('click:loadContact', contract);
            this.displayContractModal = false;
        },

    },
});
