import { AxiosInstance } from "axios";
import { urls } from './types/default.type';
import moment from 'moment';


export default class defaultClass {
    protected _model: any;
    protected _urls: urls;
    protected _primaryKey: string;
    private _api: AxiosInstance;
    constructor(api: AxiosInstance) {
        this._api = api;
        this._primaryKey = '';
        this._urls = {};
    }

    public async save(): Promise<boolean|string[]> {
        return this._save();
    }

    public async get() {
        this._get();
    }

    public type(id: string) {
        return typeof(this._model[id]);
    }

    public set(id: string, value: any) {
        if (/Date/.test(id)) {
            this._model[id] = moment(value.toString()).format('YYYY-MM-DD');
            return;
        }
        return this._model[id] = value;
    }

    public toArray() {
        return this._model;
    }

    public print() {
        console.log(this._model);
    }

    protected async _save(): Promise<boolean> {
        return new Promise((resolve, reject) => {
            const config = {
                url: this._urls.post,
                method: 'post',
                data: {
                    body: this._model
                }
                
            };
            this._api.request(config).then((response) => {
                console.log(typeof(response.data.id));
                if (typeof(response.data.id) === 'undefined') {
                    if (typeof(response.data.ids) !== 'undefined') {
                        resolve(response.data.ids);
                        return;
                    }
                    resolve(true);
                    return;
                }

                this._model[this._primaryKey] = response.data.id;
                this._get().then((data) => {
                    resolve(true);
                });
            }).catch((error) => {
                reject(error);
            });
        });
    }

    protected async _get(): Promise<boolean> {
        return new Promise((resolve, reject) => {
            const config = {
                url: this._urls.get?.replace('{'+this._primaryKey+'}', this._model[this._primaryKey]),
                method: 'get',
            };

            this._api.request(config).then((response) => {
                this._model = response.data.data;

                resolve(true);
            }).catch((error) => {
                reject(error);
            });
        });
    }
}