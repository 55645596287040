
    import { defineComponent } from 'vue';
    import TopBar from './TopBar.vue';

    export default defineComponent({
        props: {
            title: String,
            showMenuButton: Boolean,
            currentUser: Object,
            currentPage: String
        },
        components: {
            TopBar
        },
        data() {
            return {
            }
        }
    })
