
import { Field } from '@/helpers/Fields.interface';
import { defineComponent } from 'vue';
export default defineComponent({
    components: {
    },
    async mounted() {
        this.ready = false;
        const fields = this.createJob._fields.steps.reduce((acc: Promise<Field>[], step: Field[]) => {
            const fields = step.reduce((acc: Promise<Field>[], field: Field) => {
                acc.push(this.getValue(field));
                return acc;
            }, []);
            return [...acc, ...fields];
        }, []);
        await Promise.all(fields);
        this.ready = true;
        return
    },
    props: {
        createJob: {
            type: Object,
            required: true
        },
    },
    data() {
        return {
            ready: false
        }
    },
    methods: {
        async getValue(field: Field) {
            if (field.type === 'dropdown') {
                const config = {
                    url: field.values,
                    method: 'get',
                }
                const response = await this.$root?.$data.API.request(config);
                const options = response.data;
                if ((typeof field?.value !== 'undefined') && (typeof options[field.value] !== 'undefined')) { 
                    field.fakeValue = options[field.value];
                    return field;
                }
            }
            if (field.type === 'search') {
                const config = {
                    url: field.values,
                    method: 'get',
                }
                const response = await this.$root?.$data.API.request(config);
                const options = response.data;
                if ((typeof field?.value !== 'undefined') && (typeof options[field.value] !== 'undefined')) { 
                    console.log("b", options[field.value]);
                    field.fakeValue = options[field.value];
                    return field;
                }
            }
            field.fakeValue = field.value;    
            return field;
        }
    },
});
