import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "form-floating mb-3" }
const _hoisted_2 = ["id", "readonly", "disabled", "placeholder", "value"]
const _hoisted_3 = ["for"]
const _hoisted_4 = { class: "invalid-feedback" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("textarea", {
      class: _normalizeClass(["form-control", {'is-invalid': _ctx.errors}]),
      id: _ctx.id,
      readonly: _ctx.readonly,
      disabled: _ctx.readonly,
      placeholder: _ctx.label,
      value: _ctx.modelValue,
      style: {"min-height":"150px"},
      onBlur: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleBlur && _ctx.handleBlur(...args))),
      onInput: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleInput && _ctx.handleInput(...args)))
    }, null, 42, _hoisted_2),
    _createElementVNode("label", {
      for: _ctx.id,
      class: "form-label"
    }, _toDisplayString(_ctx.label), 9, _hoisted_3),
    _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.errorMessage), 1)
  ]))
}