
    import { PropType, defineComponent } from 'vue';
    
    import DateInput from '@/components/inputs/DateInput.vue'
    import TextInput from '@/components/inputs/TextInput.vue'
    import TextareaInput from '@/components/inputs/TextareaInput.vue'
    import NumberInput from '@/components/inputs/NumberInput.vue'
    import SearchInput from '@/components/inputs/SearchInput.vue'
    import DropdownInput from '@/components/inputs/DropdownInput.vue'
    import CustomInput from '@/components/inputs/CustomInput.vue'
    import Button from '@/components/inputs/Button.vue'
    import FieldDescription from '@/components/layout/FieldDescription.vue'
    import { Field } from '@/modules/CreateServiceContract/helpers/Fields.interface';
    import { Hidden } from '@/helpers/Fields.interface';
import SearchDropdownInput from '../inputs/SearchDropdownInput.vue';
export default defineComponent({
    components: {
    CustomInput,
    TextInput,
    TextareaInput,
    DateInput,
    NumberInput,
    SearchInput,
    DropdownInput,
    FieldDescription,
    Button,
    SearchDropdownInput
},
    emits: ['update:modelValue', 'update:error', 'update:value', 'click'],
    props: {
        modelValue: {
            type: Object as PropType<Field>, 
            required: true, 
        }
    }, 
    computed: {
        searchValue: {
            get() { return String(this.modelValue.value)},
            async set(value: string) {
                await (this.modelValue.value = value)
            }
        }
    },
    watch: {
        modelValue: {
            handler: function (newValue, oldValue) {
                this.$emit('update:modelValue', newValue);
                if (newValue.error !== oldValue.error) {
                    this.$emit('update:error', { error: newValue.error, id: newValue.id })
                }
            }, deep: true
        },
        "modelValue.value": {
            handler(newValue, oldValue ) {
                if (newValue !== oldValue) {
                    this.$emit('update:value', { value: newValue, id: this.modelValue.id })
                }
            }
        }
    },
    methods: {
        showField: function(type: string): Boolean {
            if (this.modelValue.type != type) {
                return false;
            }
            if (this.modelValue.hidden == undefined) {
                return true;
            }
            return this.operator(this.modelValue.hidden);
        },
        operator: function (hidden: Hidden) {
            if (typeof hidden.operator == 'undefined' && typeof hidden.value !== 'undefined') {
                return !Boolean(hidden.value);
            }
            return true;
        },
        click(x: any) {
            this.$emit('click', x)
        }
    }
});

