
import { defineComponent } from 'vue';
import Layout from './components/layout/Layout.vue';
import { API } from '@/helpers/API';

export default defineComponent({
    components: {
        Layout,
    },
    inject: ['msal'],
    data: () => ({
        debug: true,
        showMenuButton: false,
        title: 'Ax App Suite',
        API: API,
        currentPage: '',
        currentUser: {
            name: '',
            email: ''
        }
    }),
    mounted: function() {
        const msal = this.msal as any;
        this.currentUser.name = msal.name;
        this.currentUser.email = msal.email;

        this.emitter.on('logout', () => {
            msal.logout();
        });
    },
    watch: {
        $route: {
            immediate: true,
            handler(to, from) {
                this.showMenuButton = to.meta.showMenuButton || false;
                this.title = to.meta.title || 'Ax App Suite';
                this.currentPage = to.name;
            }
        }
    },
    methods: {
        isDebug (): boolean {
            return this.debug;
        },

        async validateField(field: string, value: string, rules: string) {
            return new Promise((resolve) => {

                // TODO Remove this when all fields are validated
                if (!rules) {
                    resolve({errors: false, message: ''});
                    return;
                }

                if (!/required/.test(rules ?? '') && value == '') {
                    resolve({errors: false, message: ''});
                    return;
                }

                const config = {
                    url: 'utils/validate/field',
                    method: 'get',
                    params: {}
                }

                const params: any = {}
                field = field.replace(/[^a-zæøåA-ZÆØÅ0-9-_]/g, '_');
                params[field] = value;
                params['field'] = field;
                params['rules'] = rules;

                config.params = params;

                this.API.request(config).then((response: any) => {
                    resolve({errors: !response.data.success, message: response.data.message});
                })
                .catch((error: any) => {
                    console.error(error);
                    resolve({errors: true, message: 'Kunne ikke validere feltet'});
                })
            });
        }
    }

});
