
import { defineComponent } from 'vue';

import { Field } from '@/modules/CreateServiceContract/helpers/Fields.interface';
import FieldsComponent from '@/components/layout/Fields.vue'

export default defineComponent({
    emits: ['errors', 'change'],
    components: {
        FieldsComponent,
    },
    props: {
        id: {
            type: Number, 
            required: true
        }, 
        agreementID: {
            type: String, 
            required: false
        }, 
        taskID: {
            type: String, 
            required: false
        }
    },
    mounted() { 
        this.getFields();
    },
    data: function () {
        return {
            loading: true, 
            fields: [] as Field[]
        }
    }, 
    computed: {
        config: function () {
            return {
                url: "customerlookup/fields/customer/" + this.id,
                method: 'get',
            }
        }
    },
    
    methods: {
        valueChanged(event: Event) {
            this.$emit('change', true);
        },
        errorChanged(event :Event) {
            const errors = this.fields.some(field => Boolean(field.errors)); 
            this.$emit('errors', errors);
        },
        
        async getFields() { 
            this.loading = true;
            try { 
                const response = await this.$root?.$data.API.request(this.config)
                this.fields = response.data;
            } catch (error) {
                console.error(error);
                this.loading = true;
            }
            this.loading = false;
            
        }, 

        
    
    }
})
