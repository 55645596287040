import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, createBlock as _createBlock, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '../../assets/logo.png'


const _withScopeId = n => (_pushScopeId("data-v-6693349c"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  id: "appSelector"
}
const _hoisted_2 = {
  class: "row align-self-end",
  id: "loginInfo"
}
const _hoisted_3 = { class: "col" }
const _hoisted_4 = { class: "col text-end" }
const _hoisted_5 = {
  class: "row align-items-center",
  id: "topmenu"
}
const _hoisted_6 = { class: "col-3" }
const _hoisted_7 = { class: "col text-center" }
const _hoisted_8 = { class: "col-3 text-end" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppSelector = _resolveComponent("AppSelector")!
  const _component_b_icon_grid_3x3_gap_fill = _resolveComponent("b-icon-grid-3x3-gap-fill")!
  const _directive_click_outside = _resolveDirective("click-outside")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.showAppMenu)
      ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_AppSelector, {
            smallText: true,
            onClose: _ctx.closeAppMenu,
            currentPage: _ctx.currentPage
          }, null, 8, ["onClose", "currentPage"]),
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.currentUser?.name ?? _ctx.currentUser?.email ?? ''), 1),
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("button", {
                class: "btn btn-sm btn-primary",
                onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.logOut && _ctx.logOut(...args)))
              }, "Log ud")
            ])
          ])
        ])), [
          [_directive_click_outside, _ctx.closeAppMenu]
        ])
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("img", {
          alt: "AX app logo",
          class: "img-fluid pointer",
          src: _imports_0,
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.goToFrontpage()))
        })
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("h3", null, _toDisplayString(_ctx.title), 1)
      ]),
      _createElementVNode("div", _hoisted_8, [
        (_ctx.showMenuButton)
          ? (_openBlock(), _createBlock(_component_b_icon_grid_3x3_gap_fill, {
              key: 0,
              id: "menuButton",
              onClick: _ctx.toggleAppMenu
            }, null, 8, ["onClick"]))
          : _createCommentVNode("", true)
      ])
    ])
  ], 64))
}