import { hasRole } from '@/helpers/Roles';
export default class AppList {
    private _list: any;

    constructor() {
        this._list = [
            {
                name: 'customer_lookup',
                title: 'Kunde Opslag',
                link: '/KundeOpslag',
                icon: 'file-earmark-person',
                role: 'customer.reader'
            },
            {
                name: 'create_servicecontract',
                title: 'Opret Servicekontrakt',
                link: '/OpretServiceKontrakt',
                icon: 'card-checklist',
                role: 'servicecontract.writer'
            },
            {
                name: 'create_job',
                title: 'Opret Opgave',
                link: '/OpretOpgave',
                icon: 'list-task',
                role: 'job.writer'
            },
            {
                name: 'service_app',
                title: 'Service App',
                link: '/ServiceApp',
                icon: 'archive',
                role: 'serviceapp.reader'
            },
            {
                name: 'engine_rules',
                title: 'Motor Regler',
                link: '/MotorRegler',
                icon: 'sliders',
                role: 'enginerules.reader'
            },
        ];
    }

    get list(): any {
        return this._list.filter((item: any) => {
            return hasRole(item.role);
        })
    }
}