
    import SearchModal from '@/components/widgets/SearchModal.vue'
    import { defineComponent } from 'vue';

    export default defineComponent({
        components: {
            SearchModal
        },
        emits: ['update:modelValue', 'update:errors', 'update'],
        props: {
            modelValue: String,
            errors: Boolean,
            label: String,
            id: String,
            values: String,
            primary: String,
            exclude: String,
            display: String,
            multiple: Boolean,
            readonly: {
                type: Boolean,
                required: false,
                default: false
            },
            rules: String
        },
        async mounted() {
            this.updateValues();
            // called when the route changes so make sure it updates
            this.emitter.on('route-updated', (obj: any) => { 
                this.getValues(this.modelValue);
            })
        },
        watch: { 
            modelValue() { 
                this.updateValues();
            }
        },
        data() {
            return {
                options: {},
                errorMessage: '',
                fakeValue: '',
                mounting: false,
                fieldSearchModal: {
                    excludedKeys: [this.exclude],
                    display: false,
                    multiple: this.multiple,
                    title: 'Søg',
                    searchPath: this.values,
                    text: 'Søg efter '+this.label,
                    okBtn: 'Vælg',
                    enableX: true,
                    primaryKey: this.primary,
                    cancelBtn: 'Fortryd',
                    ok: (result: any) => { 
                        this.searchFound(result);
                    },
                    cancel: () => { this.hideSearchModal() }
                },
            }
        },
        methods: {
            clearValue() {
                this.fakeValue = '';
                this.$emit('update:modelValue', null);
            },
            showSearchModal() {
                this.fieldSearchModal.display = true;
            },

            hideSearchModal() {
                this.fieldSearchModal.display = false;
            },

            searchFound(result: any) {
                this.$emit('update:modelValue', String(result));
                this.$emit('update', this.id);
                this.getValues(result);
                this.hideSearchModal();
            },

            async getValues(Ids: any) {
                try { 
                    let fakeValues = [];
                    if (this.multiple) {
                        for (let i = 0; i < Ids.length; i++) {
                            fakeValues.push(this.getValue(Ids[i]));
                        }
                        return;
                    }
                    fakeValues.push(this.getValue(Ids));
                    fakeValues = await Promise.all(fakeValues);
                    this.fakeValue = fakeValues.join(', ');
                } catch (error) {
                    this.fakeValue = 'Kunne ikke hente værdi';
                    console.error(error);
                }
            },

            async getValue(id: any) {
                if (id == null || id == '')
                {
                    return;
                }

                const config = {
                    url: this.values,
                    method: 'get',
                    params: {
                        id: id
                    }
                }
                try { 
                    const response = await this.$root?.$data.API.request(config);
                    if (this.display) {
                        return response.data[this.display] as string;
                    } else if (this.primary) { 
                        return response.data[this.primary] as string;
                    }
                    
                }catch(error){
                    throw error
                }
            },

            handleInput(event: any) {
                this.$emit('update:modelValue', event.target.value);
            },
            updateValues() {
                if (this.modelValue && this.multiple) {
                    this.getValues(this.modelValue.split(','));
                } else if(this.modelValue) { 
                    this.getValues(this.modelValue);
                }
            }
        },
    });
