import { AxiosInstance } from "axios";
import { DeliveryAddressID,
    CountryID, DeliveryAddressAgreementID,
    DeliveryName, CompanyName2, CompanyName, Address, Address2, City,
    ZipCodes, Region, PhoneNo, WebSite, LocationCode, ContactPerson,
    ContactPersonEmail, ContactPersonPhone, Picture, CompanyInfoNo,
    CompanyRegNo, SwiftCode, ChangedDate, ChangedBy, Deleted, CreatedDate,
    CreatedBy, DeliveryAddressDTO } from './types/deliveryAddress.type';
import defaultClass from './defaultClass';
import { urls } from './types/default.type';

export default class DeliveryAddress extends defaultClass {
    protected _model: DeliveryAddressDTO;
    protected _primaryKey: string;
    protected _urls: urls;

    constructor(api: AxiosInstance, deliveryAddressId: DeliveryAddressID = null) {
        super(api);
        this._model = {
            DeliveryAddressID: null,
            CountryID: null,
            DeliveryAddressAgreementID: null,
            DeliveryName: null,
            CompanyName2: null,
            CompanyName: null,
            Address: null,
            Address2: null,
            City: null,
            ZipCodes: null,
            Region: null,
            PhoneNo: null,
            WebSite: null,
            LocationCode: null,
            ContactPerson: null,
            ContactPersonEmail: null,
            ContactPersonPhone: null,
            Picture: null,
            CompanyInfoNo: null,
            CompanyRegNo: null,
            SwiftCode: null,
            ChangedDate: null,
            ChangedBy: null,
            Deleted: null,
            CreatedDate: null,
            CreatedBy: null,
        }

        this._primaryKey = 'DeliveryAddressID';

        this._urls = {
            get: 'delivery-address/{DeliveryAddressID}',
            post: 'delivery-address',
            delete: 'delivery-address/{DeliveryAddressID}',
            put: 'delivery-address/{DeliveryAddressID}',
        };

        if (deliveryAddressId) {
            this._model.DeliveryAddressID = deliveryAddressId;
            this._get();
        }
    }

    

    private _fill(deliveryAddress: DeliveryAddressDTO) {
        this._model = deliveryAddress;
    }

    
    get DeliveryAddressID(): DeliveryAddressID {
        return this._model.DeliveryAddressID;
    }

    get CountryID(): CountryID {
        return this._model.CountryID;
    }

    get DeliveryAddressAgreementID(): DeliveryAddressAgreementID {
        return this._model.DeliveryAddressAgreementID;
    }

    get DeliveryName(): DeliveryName {
        return this._model.DeliveryName;
    }

    get CompanyName2(): CompanyName2 {
        return this._model.CompanyName2;
    }

    get CompanyName(): CompanyName {
        return this._model.CompanyName;
    }

    get Address(): Address {
        return this._model.Address;
    }

    get Address2(): Address2 {
        return this._model.Address2;
    }

    get City(): City {
        return this._model.City;
    }

    get ZipCodes(): ZipCodes {
        return this._model.ZipCodes;
    }

    get Region(): Region {
        return this._model.Region;
    }

    get PhoneNo(): PhoneNo {
        return this._model.PhoneNo;
    }

    get WebSite(): WebSite {
        return this._model.WebSite;
    }

    get LocationCode(): LocationCode {
        return this._model.LocationCode;
    }

    get ContactPerson(): ContactPerson {
        return this._model.ContactPerson;
    }

    get ContactPersonEmail(): ContactPersonEmail {
        return this._model.ContactPersonEmail;
    }

    get ContactPersonPhone(): ContactPersonPhone {
        return this._model.ContactPersonPhone;
    }

    get Picture(): Picture {
        return this._model.Picture;
    }

    get CompanyInfoNo(): CompanyInfoNo {
        return this._model.CompanyInfoNo;
    }

    get CompanyRegNo(): CompanyRegNo {
        return this._model.CompanyRegNo;
    }

    get SwiftCode(): SwiftCode {
        return this._model.SwiftCode;
    }

    get ChangedDate(): ChangedDate {
        return this._model.ChangedDate;
    }

    get ChangedBy(): ChangedBy {
        return this._model.ChangedBy;
    }

    get Deleted(): Deleted {
        return this._model.Deleted;
    }

    get CreatedDate(): CreatedDate {
        return this._model.CreatedDate;
    }

    get CreatedBy(): CreatedBy {
        return this._model.CreatedBy;
    }

    set DeliveryAddressID(value: DeliveryAddressID) {
        this._model.DeliveryAddressID = value;
    }

    set CountryID(value: CountryID) {
        this._model.CountryID = value;
    }

    set DeliveryAddressAgreementID(value: DeliveryAddressAgreementID) {
        this._model.DeliveryAddressAgreementID = value;
    }
    
    set DeliveryName(value: DeliveryName) {
        this._model.DeliveryName = value;
    }

    set CompanyName2(value: CompanyName2) {
        this._model.CompanyName2 = value;
    }

    set CompanyName(value: CompanyName) {
        this._model.CompanyName = value;
    }

    set Address(value: Address) {
        this._model.Address = value;
    }

    set Address2(value: Address2) {
        this._model.Address2 = value;
    }

    set City(value: City) {
        this._model.City = value;
    }

    set ZipCodes(value: ZipCodes) {
        this._model.ZipCodes = value;
    }

    set Region(value: Region) {
        this._model.Region = value;
    }

    set PhoneNo(value: PhoneNo) {
        this._model.PhoneNo = value;
    }

    set WebSite(value: WebSite) {
        this._model.WebSite = value;
    }

    set LocationCode(value: LocationCode) {
        this._model.LocationCode = value;
    }

    set ContactPerson(value: ContactPerson) {
        this._model.ContactPerson = value;
    }

    set ContactPersonEmail(value: ContactPersonEmail) {
        this._model.ContactPersonEmail = value;
    }

    set ContactPersonPhone(value: ContactPersonPhone) {
        this._model.ContactPersonPhone = value;
    }

    set Picture(value: Picture) {
        this._model.Picture = value;
    }

    set CompanyInfoNo(value: CompanyInfoNo) {
        this._model.CompanyInfoNo = value;
    }

    set CompanyRegNo(value: CompanyRegNo) {
        this._model.CompanyRegNo = value;
    }

    set SwiftCode(value: SwiftCode) {
        this._model.SwiftCode = value;
    }

    set ChangedDate(value: ChangedDate) {
        this._model.ChangedDate = value;
    }

    set ChangedBy(value: ChangedBy) {
        this._model.ChangedBy = value;
    }

    set Deleted(value: Deleted) {
        this._model.Deleted = value;
    }

    set CreatedDate(value: CreatedDate) {
        this._model.CreatedDate = value;
    }

    set CreatedBy(value: CreatedBy) {
        this._model.CreatedBy = value;
    }

}