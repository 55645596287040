import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, createBlock as _createBlock, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = {
  key: 0,
  class: "col-auto mx-0 px-0"
}
const _hoisted_3 = {
  type: "button",
  style: {"visibility":"hidden"},
  class: "btn btn-link mx-0 px-0"
}
const _hoisted_4 = { class: "row" }
const _hoisted_5 = { class: "col text-end" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_b_icon_trash = _resolveComponent("b-icon-trash")!
  const _component_ServiceTaskRow = _resolveComponent("ServiceTaskRow")!
  const _component_b_icon_plus = _resolveComponent("b-icon-plus")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.serviceContract.serviceTaskFields, (taskField, fi) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "col fw-bold",
          key: fi
        }, _toDisplayString(taskField.label), 1))
      }), 128)),
      (_ctx.deletable)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createElementVNode("button", _hoisted_3, [
              _createVNode(_component_b_icon_trash)
            ])
          ]))
        : _createCommentVNode("", true)
    ]),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.field.value, (taskRow, ti) => {
      return (_openBlock(), _createBlock(_component_ServiceTaskRow, {
        onDeleteRow: ($event: any) => (_ctx.deleteRow(ti)),
        deletable: _ctx.deletable,
        serviceContract: _ctx.serviceContract,
        taskRow: taskRow,
        key: ti
      }, null, 8, ["onDeleteRow", "deletable", "serviceContract", "taskRow"]))
    }), 128)),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("button", {
          type: "button",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.addRow && _ctx.addRow(...args))),
          class: "btn btn-link"
        }, [
          _createVNode(_component_b_icon_plus),
          _createTextVNode("Tilføj Serviceopgave")
        ])
      ])
    ])
  ], 64))
}