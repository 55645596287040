
    import { defineComponent } from 'vue';
    import Summary from '@/modules/CreateJob/components/Summary.vue';
    import StepProgess from '@/components/widgets/StepProgress.vue'
    import ConfirmDialog from '@/components/widgets/ConfirmDialog.vue'
    import SearchModal from '@/components/widgets/SearchModal.vue'
    import CreateJob from '../helpers/CreateJob'
    import Fields from '@/components/layout/Fields.vue'
    import { Column, Section, Field, Hidden } from '@/helpers/Fields.interface'
    import Saved from '../components/Saved.vue';
    import LoadAddress from '../components/LoadAddress.vue';
    import LoadJobInfo from '../components/LoadJobInfo.vue';

    export default defineComponent({
        components: {
    StepProgess,
    ConfirmDialog,
    SearchModal,
    Fields,
    Summary,
    Saved,
            LoadAddress,
            LoadJobInfo
},
        data: function() {
            return {
                createJob: new CreateJob(this.$router, this.$route, this.$root?.$data.API),
                errors: false,
                ready: false,
                restoreDialog: {
                    display: false,
                    title: 'Forsæt fra hvor du slap',
                    text: 'Vil du genoptage sidste indtastning?',
                    okBtn: 'Ja',
                    enableX: false,
                    cancelBtn: 'Nej',
                    ok: () => { this.restore() },
                    cancel: () => { this.destroy() }
                },
            }
        },
        methods: {
            async action(event: any) {
                if(event.action === 'delete-row') {
                    this.createJob.deleteLine(event.index);
                }
            },
            async addLine() { 
                if(this.createJob.currentStep !== this.createJob.serviceopgaverIndex) {
                    return;
                }
                this.createJob.addLine();
            },
            async fieldUpdated(field: Field) {
                if (field.id === 'ServiceAgreement.ServiceAgreementID') {
                    this.ready = false;
                    await this.createJob.buildContractInformation(Number(field.value));
                    await this.createJob.buildTaskInformation(Number(field.value));
                    await this.createJob.buildServiceTasksInformation();
                    this.ready = true;
                }
                if (field.id === 'DeliveryAddress.DeliveryName') {
                    this.ready = false;
                    await this.createJob.buildTaskInformation(null, Number(field.value));
                    await this.createJob.buildServiceTasksInformation(Number(field.value));
                    this.ready = true;
                }
                if(field.id = 'TaskDescription.TaskType'){
                    // find next TaskDescription.TaskDescriptionID
                    let taskTypeId = null;
                    let taskType = null;
                    for (let field of this.createJob.fields) {
                        if(field.id == 'TaskDescription.TaskType'){
                            taskType = field.value;
                        }
                        if(field.id == 'TaskDescription.TaskDescriptionID'){
                            //find prev TaskDescription.TaskType
                            if (typeof taskType !== 'undefined' && taskType !== null) {
                                field.value = taskType;
                            }
                            
                        }
                    }
                }
                if(field.id = 'TaskDescription.TaskDescriptionID'){
                }

                this.createJob.saveSession();

            },
            goToStep: function(step: number): void {
                if (step > this.createJob.maxStep) {
                    return;
                }

                this.createJob.currentStep = step;
            },

            async waitForReady() {
                while(this.createJob.ready === false) {
                    await new Promise(resolve => setTimeout(resolve, 50));
                }

                this.ready = this.createJob.ready;
                this.errors = this.createJob.errors;

            },

            async previousStep() {
                this.ready = false;
                await this.createJob.previousStep();
                this.errors = this.createJob.errors;
                this.ready = true;
            },

            async nextStep() {
                this.ready = false;
                await this.createJob.nextStep();
                this.errors = this.createJob.errors;
                this.ready = true;
            },

            showField(field: Field): Boolean {
                if (field.hidden == undefined) {
                    return true;
                }

                return this.operator(field.hidden);
            },

            operator(hidden: Hidden) {
                const operators: any = {
                    '==': function(a: string | number, b: string | number) { return a == b },
                    '!=': function(a: string | number, b: string | number) { return a != b },
                };

                if (hidden.operator in operators)
                {
                    // todo
                    // return !operators[hidden.operator](this.createJob.getFieldValue(hidden.field), hidden.value);
                }
                return false;
            },

            async restore() {
                this.restoreDialog.display = false;
                this.ready = false;
                await this.createJob.restoreSession();
                this.ready = true;
            },

            async destroy() {
                this.restoreDialog.display = false;
                this.ready = false;
                await this.createJob.destroySession();
                this.ready = true;
            },
            startOver() {
                this.createJob.reset();
            }

        },
        computed: {
            nextStepReady: function(): Boolean { 
                return this.createJob.nextStepReady();
            }
        },
        mounted() {
            if (this.createJob.haveSession) {
                this.restoreDialog.display = true;
            }
            this.waitForReady();
        },
        watch: {
            createJob: {
                handler: function() {
                    this.ready = this.createJob.ready;
                    this.errors = this.createJob.errors;
                },
                deep: true
            }
        },
    });
