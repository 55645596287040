
    import { defineComponent } from 'vue';

    export default defineComponent({
        components: {
        },
        emits: ['update:modelValue', 'update:errors', 'update'],
        props: {
            id: String,
            label: String,
            showLabel: {
                type: Boolean,
                required: false,
                default: true
            },
            fieldChanged: String,
            compact: {
                type: Boolean,
                required: false,
                default: false
            },
            serviceContract: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
                errorMessage: '',
                showValue: '' as any
            }
        },
        mounted() {
            if (this.id == 'Debtor.SK.Price') {
                this.showValue = '';
            }
        },
        methods: {
            getSKTotalPrice() {
                const fieldValue = this.serviceContract.getFieldValue('Debtor.SK.IDS');
                const ids = fieldValue.replace('SK', '').split(',');
                const config = {
                    url: 'service-agreement/price/service-agreements',
                    method: 'get',
                    params: {
                        ServiceAgreementTypeIDs: ids
                    }
                }
                this.$root?.$data.API.request(config).then((response: any) => {
                    this.showValue = response.data + ' kr';
                })
                .catch((error: any) => {
                    this.showValue = 'Kunne ikke hente værdi';
                    console.error(error);
                })
            }
        },
        watch: {
            fieldChanged: function (newValue) {
                console.log(newValue)
                if (/Debtor\.SK\.IDS/.test(newValue)) {
                    this.getSKTotalPrice();
                }
            }
        }
    });
