import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-bd83bf8c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "nav nav-tabs" }
const _hoisted_2 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("ul", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.steps, (stepName, stepId) => {
      return (_openBlock(), _createElementBlock("li", {
        key: stepId,
        class: _normalizeClass({'nav-item': true, error: false, changed: false}),
        onClick: ($event: any) => (_ctx.$emit('stepClicked', stepId))
      }, [
        _createElementVNode("div", {
          class: _normalizeClass({active: (stepId == _ctx.currentStep), 'nav-link': true})
        }, _toDisplayString(stepName), 3)
      ], 8, _hoisted_2))
    }), 128))
  ]))
}