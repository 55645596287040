import { AxiosInstance } from "axios";
import { Fields, Section, Field, Step, Column } from "@/helpers/Fields.interface"
import { SaveResponse } from "./CreateJobTypes";

export default class CreateJob {
    private _ready: boolean = false;
    private _errors: boolean = false;
    private _currentStep = 0;
    private _maxStep = 0;
    private _route: any;
    private _router: any;

    private _api: AxiosInstance;
    private _steps: string[];
    private _fields: { steps: Field[][] };
    private _customerId?: number | undefined;
    private _contractId?: number;
    private _addressId?: number;

    private _saved: SaveResponse = null;

    constructor(router: any, route: any, api: AxiosInstance) {
        this._api = api;
        this._steps = this._buildSteps();
        this._router = router;
        this._route = route;

        this._fields = {
            steps: []
        }

        if (route.params.stepName) {
            this.currentStep = this._steps.indexOf(route.params.stepName);
            this._maxStep = this._currentStep;
        } else {
            this.currentStep = 0;
            this._maxStep = 0;
        }

        this.initSteps();
    }

    private async _save() {
        const config = {
            url: 'createjob/fields',
            method: 'post',
            data: {
                body: this._fields
            }
        }
        try {
            const response = await this._api.request(config);
            this._saved = response.data;
            if (response.status == 200) {
                // todo create done page
                this._router.push('/OpretOpgave/Gemt');
            } else {
                console.error(response);
                this._errors = true;
            }
        } catch (error) {
            console.error(error);
            this._errors = true;
        }
    }

    public get saved(): SaveResponse {
        return this._saved;
    }

    public get customerId(): number | undefined {
        return this._customerId;
    }

    public get hasContract(): boolean {
        return Boolean(this._contractId);
    }

    public get contractId(): number | undefined {
        return this._contractId;
    }

    public get addressId(): number | undefined {
        return this._addressId;
    }

    public set customerId(value: number | undefined) {
        this._customerId = value;
    }

    private _buildSteps(): string[] {
        return [
            'Kundeoplysninger',
            'Job opgaveinformation',
            'Serviceopgaver',
            'Opsummering',
            'Gemt'
        ]
    }

    public async initSteps(customerId?: number, contractId?: number): Promise<void> {
        const buildCustomerInformation = await this.buildCustomerInformation(customerId);
        const buildContractInformation = await this.buildContractInformation(contractId);
        const buildTaskInformation = await this.buildTaskInformation();
        const buildServiceTasksInformation = await this.buildServiceTasksInformation();

        await Promise.all([
            buildCustomerInformation,
            buildContractInformation,
            buildTaskInformation,
            buildServiceTasksInformation,
        ]);

        this._ready = true;
    }


    public async _buildSummery() {
        this._fields.steps[this._steps.indexOf('Opsummering')] = [];
    }

    public async buildCustomerInformation(customerId?: number | null, contractId?: number | null): Promise<void> {
        return new Promise((resolve) => {
            let url = 'createjob/fields/customer';
            if (customerId) {
                url += '/' + customerId;
            } else if (contractId) {
                url += '/contract/' + contractId;
            }
            const config = {
                url,
                method: 'get',
                params: {
                    new: !Boolean(customerId || contractId),
                }
            }
            this._api.request(config)
                .then((response) => {
                    this._fields.steps[this._steps.indexOf('Kundeoplysninger')] = response.data;
                })
                .catch((error) => {
                    console.error(error);
                    this._errors = true;
                })
                .finally(() => {
                    if (customerId) {
                        this._customerId = customerId;
                    }
                    if (contractId) { 
                        this._contractId = contractId;
                    }
                    resolve();
                });
        });
    }


    public get summeryIndex(): number {
        return this._steps.indexOf('Opsummering');
    }
    
    public get savedIndex(): number {
        return this._steps.indexOf('Gemt');
    }

    public get serviceopgaverIndex(): number {
        return this._steps.indexOf('Serviceopgaver');
    }
    
    public get kundeOplysningerIndex(): number {
        return this._steps.indexOf('Kundeoplysninger');
    }

    public get jobInfoIndex(): number { 
        return this._steps.indexOf("Job opgaveinformation");
    }
    /**
     * Add a new line to the serviceopgaver section
     * Assumed current step is the serviceopgaver step
     */
    public async addLine(): Promise<void> {
        this._ready = false;

        //Get the highest id
        let highestId = 0;
        for (let i = 0; i < this._fields.steps[this.serviceopgaverIndex].length; i++) { 
            if(this._fields.steps[this.serviceopgaverIndex][i].id) {
                const id = Number(this._fields.steps[this.serviceopgaverIndex][i].id);
                if (id > highestId) { 
                    highestId = id;
                }
            }
        }


        // todo add a new id parameter to the request
        const config = {
            url: 'createjob/fields/service-tasks/new-line',
            method: 'get',
            params: {
                id: highestId,
            }
        }
        try { 
            const response = await this._api.request(config);
            let subArray = response.data;
            
            for (let i in this._fields.steps[this.serviceopgaverIndex]) { 
                if (this._fields.steps[this.serviceopgaverIndex][i].id == 'Description') {
                    this._fields.steps[this.serviceopgaverIndex] = [
                        ...this._fields.steps[this.serviceopgaverIndex].slice(0, Number(i)),
                        ...subArray,
                        ...this._fields.steps[this.serviceopgaverIndex].slice(Number(i))
                    ]
                    break;
                }
            }
        } catch (error) {            
            console.error(error);
            this._errors = true;
        }
        this._ready = true;
    }


    /**
     * TODO: this is not updated to the latest version of the fields
     * @param id 
     */
    public deleteLine(id: number): void {
        // delete the line that contains the button with id
        const length = this._fields.steps[this.serviceopgaverIndex].length;
        
        // find the index of the button with id
        for (let i = 0; i < length; i++) {
            if (this._fields.steps[this.serviceopgaverIndex][i].type == 'button' && this._fields.steps[this.serviceopgaverIndex][i].id == String(id)) {
                let rowStart = 0;
                let rowEnd = length-1;
                for (let j = i; j < length; j++) { 
                    if (this._fields.steps[this.serviceopgaverIndex][j].type == 'new_row') { 
                        rowEnd = j-1;
                        break;
                    }
                }
                for (let k = i; k > 0; k--) { 
                    if (this._fields.steps[this.serviceopgaverIndex][k].type == 'new_row') { 
                        rowStart = k;
                        break;
                    }
                }
                if (rowStart == 0 && rowEnd >= length-1) {

                } else { 
                    this._fields.steps[this.serviceopgaverIndex].splice(rowStart, rowEnd-rowStart+1);
                }
                break;
            }
        }
    }

    public async buildContractInformation(contractId?: number): Promise<void> {
        return new Promise((resolve) => {
        let url = 'createjob/fields/contract';
        if (this._customerId) {
            url += '/' + this._customerId;
            if (contractId) {
                url += '/' + contractId;
            }
        }
        const config = {
            url,
            method: 'get',
        }
        this._api.request(config)
            .then((response) => {
                this._fields.steps[this._steps.indexOf('KontraktInformation')] = response.data;
            })
            .catch((error) => {
                console.error(error);
                this._errors = true;
            })
            .finally(() => {
                this._contractId = contractId;
                resolve();
            });
        });
    }

    public async buildTaskInformation(contractId?: number | null, addressId?: number | null, customerId?: number | null): Promise<void> {
        try { 
            let url = 'createjob/fields/task-information';
            if (!contractId) {
                contractId = this._contractId;
            }
            if (!customerId) {
                customerId = this._customerId;
            }
            if (!addressId) {
                addressId = this._addressId;
            }

            if (customerId && addressId) {
                url += '/customer/' + customerId + '/' + addressId;
            } else if (contractId) {
                if (addressId) {
                    url += '/' + contractId + '/' + addressId;
                } else {

                    url += '/' + contractId;
                }
            } else if(customerId) { 
                url += '?new=false';
            }
                
            const config = {
                url,
                method: 'get',
            }
            const response = await this._api.request(config)
            this._fields.steps[this._steps.indexOf('Job opgaveinformation')] = response.data;
            const deliveryName = this._fields.steps[this._steps.indexOf('Job opgaveinformation')].find(x => x.id == 'DeliveryAddress.DeliveryName');
            if (deliveryName) { 
                this._addressId = Number(deliveryName.value);
            }
        }catch(error) { 
            console.error(error);
            this._errors = true;
        }
        if (addressId) { 
            this._addressId = addressId;
        }
    }

    public async buildServiceTasksInformation(addressId?: number|null): Promise<void> {

        try {
            let url = 'createjob/fields/service-tasks';
            if (addressId) {
                url += '/' + addressId;
            } else if (this._addressId) {
                url += '/' + this._addressId;
            }
            const config = {
                url,
                method: 'get',
            }
            const response = await this._api.request(config)
            this._fields.steps[this._steps.indexOf('Serviceopgaver')] = response.data;
        } catch (error) {
            console.error(error);
            this._errors = true;
            
        }
    }



    async nextStep(): Promise<void> {
        
            this.currentStep = this._currentStep+1;
            this._maxStep = this._currentStep;
        await this._router.push('/OpretOpgave/' + this._steps[this._currentStep]);
        if (this._currentStep == this.savedIndex) {
            await this._save();
            this.destroySession();
            return
        }
        return;
    }


    nextStepReady(): boolean { 
        if (!this.fields || this.fields.filter(x => x.errors).length > 0) {
            return false;
        }
        return this.currentStep < this.totalSteps;
    }

    async previousStep(): Promise<void> {
        return new Promise((resolve) => {
            if (this.currentStep == this.savedIndex) { 
                this._errors = false;
            }
            if (this._currentStep > 0) {
                this._currentStep--;
                this._router.push('/OpretOpgave/' + this._steps[this._currentStep]);
            }
            resolve();
        });
    }

    getName(step: number): string {
        return this._steps[step];
    }

    set currentStep(step: number) {
        this._currentStep = step;
        if (this._currentStep == this.summeryIndex) { 
            this._buildSummery();
        }
        this._router.push('/OpretOpgave/' + this._steps[this._currentStep]);
    }

    get maxStep(): number {
        return this._maxStep;
    }

    get currentStep(): number {
        return this._currentStep;
    }

    get fields(): Field[] {
        return this._fields.steps[this.currentStep];
    }
    
    set fields(value: Field[]){
        this._fields.steps[this.currentStep] = value;
    }

    get steps(): string[] {
        return this._steps;
    }

    get totalSteps(): number {
        return this._steps.length;
    }

    get ready(): boolean {
        return this._ready;
    }

    get errors(): boolean {
        return this._errors;
    }

    get nextStepName(): string {
        if (this._currentStep < this._steps.length - 1 - 1) {
            return this._steps[this._currentStep + 1];
        }
        return 'Gem opgave';
    }

    get isLastStep(): boolean {
        return this._currentStep == this._steps.length - 1;
    }

    get previousStepName(): string {
        if (this._currentStep > 0) {
            return this._steps[this._currentStep - 1];
        }
        return 'Start forfra';
    }

    reset() {
        this.destroySession();
        this.currentStep = 0;
        this._customerId = undefined;
        this._contractId = undefined;
        this._addressId = undefined;
        this._maxStep = 0;
        this._steps = this._buildSteps();
        this.initSteps();
        
    }

    get hasFields(): boolean {
        if (typeof this._fields?.steps?.[this.currentStep] === 'undefined') return false;
        return this._fields.steps[this.currentStep] && Object.keys(this._fields.steps[this.currentStep]).length > 0;
    }


    saveSession() { 
        const data = {
            currentStep: this.currentStep,
            customerId: this._customerId,
            contractId: this._contractId,
            addressId: this._addressId,
            maxStep: this._maxStep,
            fields: this._fields
        };
        localStorage.setItem('createJobFieldValues', JSON.stringify(data));
    }
    
    restoreSession() { 
        const session = localStorage.getItem('createJobFieldValues');
        if (session) {    
            const data = JSON.parse(session);
            this.currentStep = data.currentStep;
            this._customerId = data.customerId;
            this._contractId = data.contractId;
            this._addressId = data.addressId;
            this._maxStep = data.maxStep;
            this._fields = data.fields;
        }
    }


    public get hasCustomer(): boolean {
        return Boolean(this._customerId);
    }

    public async loadCustomer(customerId: number) { 
        this._ready = false;
        this._customerId = customerId;
        try {
            await this.buildCustomerInformation(this._customerId)
        } catch (e) { 

        } finally { 
            this._ready = true;
        }
    }

    public async loadAddress(deliveryAddressId: number) { 
        this._ready = false;
        this._addressId = deliveryAddressId;
        try {
            await this.buildTaskInformation(null, this._addressId, this._customerId)
        } catch (e) { 

        } finally { 
            this._ready = true;
        }
    }

    public async loadContact(contractId: number) { 
        this._ready = false;
        this._contractId = contractId;
        try {
            await this.buildCustomerInformation(null, this._contractId)
        } catch (e) { 

        } finally { 
            this._ready = true;
        }
    }

    destroySession() { 
        localStorage.removeItem('createJobFieldValues');
    }

    get haveSession() {
        return Boolean(localStorage.getItem('createJobFieldValues'));
    }
}