
import { defineComponent } from 'vue';

import { Field } from '@/modules/CreateServiceContract/helpers/Fields.interface';
import FieldsComponent from '@/components/layout/Fields.vue'
export default defineComponent({
    components: {
        FieldsComponent,
    },
    props: {
        id: {
            type: Number, 
            required: true
        }, 
        agreementID: {
            type: String, 
            required: false
        }, 
        taskID: {
            type: String, 
            required: false
        }
        
    },
    mounted() { 
        this.getFields();
    },
    watch: {
        async config() { 
            await this.getFields();
        }
    },
    data: function () {
        return {
            loading: true, 
            fields: [] as Field[][]
        }
    }, 
    computed: {
        config: function () {
            let url: string = "customerlookup/fields/service-order";
            if (this.agreementID) {
                url += "/" + this.agreementID; 
                if (this.taskID) {
                    url += "/" + this.taskID;
                }
            }
            return {
                url,
                method: 'get',
            }
        }
    },
    methods: {
        async getFields() {
            this.loading = true;
            try {
                const response = await this.$root?.$data.API.request(this.config)
                this.fields = response.data;
            } catch (error) {
                this.loading = true;
            }
            this.loading = false;

        },
        async fieldUpdated(field: any) {
            if (field.id === 'ServiceAgreement.ServiceAgreementID') {
                const currentRoute = this.$router.currentRoute.value; 
                if (
                    currentRoute.name == 'customer_lookup_view' &&
                    !Array.isArray(currentRoute.params.id) && 
                    currentRoute.params.id == String(this.id) &&
                    currentRoute.params.step == 'DeliveryAddress' &&
                    currentRoute.params.agreementID == field.value
                ) return;
                this.$router.push({ name: 'customer_lookup_view', params: { id: this.id, step: 'DeliveryAddress', agreementID: field.value } })
            }
            if (field.id === 'ServiceTask.ServiceTaskID') { 
                const currentRoute = this.$router.currentRoute.value; 
                if (
                    currentRoute.name == 'customer_lookup_view' &&
                    !Array.isArray(currentRoute.params.id) && 
                    currentRoute.params.id == String(this.id) &&
                    currentRoute.params.step == 'DeliveryAddress' &&
                    typeof this.agreementID != 'undefined' &&
                    currentRoute.params.taskID == field.value 
                ) return;
                this.$router.push({ name: 'customer_lookup_view', params: { id: this.id, step: 'DeliveryAddress', agreementID: this.agreementID, taskID: field.value } })
            }
        }
    }
});

