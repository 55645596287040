import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "bg-info rounded p-3 h-100 mb-3" }
const _hoisted_2 = {
  key: 0,
  class: "spinner-border text-primary spinner-border-sm"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.isLoading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2))
      : _createCommentVNode("", true),
    _createTextVNode(" " + _toDisplayString(_ctx.descriptionText), 1)
  ]))
}