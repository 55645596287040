import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_b_icon_search = _resolveComponent("b-icon-search")!
  const _component_LoadJobInfoModal = _resolveComponent("LoadJobInfoModal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("button", {
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.loadAddress && _ctx.loadAddress(...args))),
      class: "btn btn-link",
      id: "load-Contract"
    }, [
      _createVNode(_component_b_icon_search),
      _createTextVNode("Indlæs fra Kunde")
    ]),
    _createVNode(_component_LoadJobInfoModal, {
      display: _ctx.displayAddressModal,
      "onClick:cancel": _ctx.closeAddressModal,
      "onClick:ok": _ctx.selectAddress,
      CustomerID: _ctx.CustomerID
    }, null, 8, ["display", "onClick:cancel", "onClick:ok", "CustomerID"])
  ], 64))
}