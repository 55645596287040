
    import { defineComponent } from 'vue';
    import { LaravelPaginationObject, LaravelPaginationLink } from './LaravelPagination.interface';

    export default defineComponent({
        components: {
        },
        emits: ['selected', 'unselected'],
        props: {
            query: String,
            config: Object,
            multiple: Boolean
        },
        data: function() {
            return {
                results: {} as LaravelPaginationObject,
                selected: [] as Object[],
                loading: true,
                lookupTimeout: null as any,
                orgUrl: this.config?.url
            }
        },
        methods: {
            selectRow(rowIndex: number, row: any) {
                const key = JSON.stringify(row);
                if (!this.isSelected(row)) {
                    if (!this.multiple) {
                        this.selected = [] as Object[];
                    }
                    this.selected.push(key);
                    this.$emit('selected', this.results.data[rowIndex]);
                } else {
                    this.selected.splice(this.selected.indexOf(key), 1);
                    this.$emit('unselected', this.results.data[rowIndex]);
                }
            },
            keyNotExcluded(key: string) {
                if (!this.config?.excludedKeys) {
                    return true;
                }
                return !this.config.excludedKeys.includes(key);
            },
            changePage(link: LaravelPaginationLink) {
                if (link.url && !link.active && this.config) {
                    this.config.url = link.url;
                    this.doLookup();
                }
            },
            doLookup() {
                this.loading = true;
                if (this.config)
                {
                    if (!this.config.params) this.config.params = {};
                    this.config.params["query"] = this.query;
                }
                this.$root?.$data.API.request(this.config).then((response: any) => {
                    this.loading = false;
                    this.results = response.data;
                })
                .catch((error: any) => {
                    this.loading = true;
                    this.results.data = [];
                    console.error(error);
                })
            },
            isSelected(row: any) {
                const key = JSON.stringify(row);
                for (const item in this.selected ) {
                    if (this.selected[item] === key) {
                        return true;
                    }
                }
                return false;
            },
            getRow(key: string|undefined) {
                if (!key) {
                    return {};
                }
                return JSON.parse(key);
            }, 
            debouncedLookup() { 
                clearTimeout(this.lookupTimeout);
                this.lookupTimeout = setTimeout(() => {
                    if (this.config && this.config.url != this.orgUrl)
                    {
                        this.config.url = this.orgUrl;
                    }
                    this.doLookup();
                }, 500);
            }
        },
        computed: {
            'rows': function() {
                if (this.results.data) {
                    if (!this.config || !this.config.excludedKeys || this.config.excludedKeys.length === 0) {
                        return this.results.data;
                    }
                    const data = JSON.parse(JSON.stringify(this.results.data));
                    return data.map((row: any) => {
                        this.config?.excludedKeys.forEach((key: string) => {
                            if (Object.keys(row).includes(key)) {
                                delete row[key];
                            }
                        });
                        return row;
                    });
                }
                return [];
            },
        },
        mounted() {
            this.doLookup();
        },
        watch: {
            query: function () { this?.debouncedLookup?.() },
            config: function () { this?.debouncedLookup?.() },
        },
    });
