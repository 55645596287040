
import { defineComponent } from 'vue';
import SearchModal from '@/components/widgets/SearchModal.vue'

export default defineComponent({
    emits: [
        'click:ok',
        'click:cancel',
    ],
    components: {
        SearchModal
    },
    async mounted() {
    
    },
    props: {
        display: {
            type: Boolean,
            default: false
        },
        primaryKey: {
            type: String,
            default: "CustomerID" 
        },
        excludedKeys: {
            type: Array,
            default: () => ['CustomerID']
        },
        searchPath: {
            type: String,
            default: 'customer/search'
        },
        text: {
            type: String,
            default: 'Søg efter eksisterende kunde'
        },
        title: {
            type: String,
            default: 'Find kunde'
        },
        enableX: {
            type: Boolean,
            default: false
        },
        okBtn: {
            type: String,
            default: 'Vælg'
        },
        cancelBtn: {
            type: String,
            default: 'Fortryd'
        },
    },
    methods: {
        ok(customer: any) { 
            this.$emit('click:ok', customer);
        },
        cancel() { 
            this.$emit('click:cancel');    
        }
    },
});
