
import { defineComponent } from 'vue';

    export default defineComponent({
        components: {
        },
        emits: ['update:modelValue', 'update:errors', 'fullvalue', 'update'],
        props: {
            modelValue: String,
            errors: Boolean,
            label: String,
            id: String,
            values: String,
            optionKey: {
                type: [String, Boolean],
                required: false,
                default: false
            },
            compact: {
                type: Boolean,
                required: false,
                default: false
            },
            showLabel: {
                type: Boolean,
                required: false,
                default: true
            },
            ready: {
                type: Boolean,
                required: false,
                default: true
            },
            readonly: {
                type: Boolean,
                required: false,
                default: false
            },
            cacheable: {
                type: Boolean, 
                required: false, 
                default: false
            },
            rules: String
        },
        data() {
            return {
                loading: true,
                options: [
                    'Henter liste'
                ],
                errorMessage: ''
            }
        },
        mounted() {
            this.getOptions();
        },
        methods: {
            async handleBlur() {
                this.$root?.validateField(this.label ?? '', this.modelValue ?? '', this.rules ?? '').then((validation: any) => {
                    this.$emit('update:errors', validation.errors);
                    this.errorMessage = validation.message;
                });
            },

            getName(name: any) {
                if (this.optionKey) {
                    return name[this.optionKey as string];
                }
                return name;
            },

            getOptions() {
                this.loading = true;
                if (!this.ready) {
                    this.$emit('update:modelValue', null);
                    this.$emit('fullvalue', null);
                    return;
                }
                const config = {
                    url: this.values,
                    method: 'get',
                    cacheable: this.cacheable
                }
                this.$root?.$data.API.request(config).then((response: any) => {
                    this.options = response.data;
                    this.loading = false;
                })
                .catch((error: any) => {
                    this.options = [
                    'Kunne ikke hente liste'
                ]
                    console.error(error);
                })
            },

            handleInput(event: any) {
                this.$emit('update:modelValue', event.target.value);
                this.$emit('fullvalue', this.options[event.target.value]);
                this.$emit('update', this.id);
            }
        },

        watch: {
            ready: function() {
                this.getOptions();
            },
            values: function() {
                this.getOptions();
            }
        }
    });
