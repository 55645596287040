import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createVNode as _createVNode, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1ee39a26"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "form-floating input-group mb-3" }
const _hoisted_2 = ["id", "placeholder", "value"]
const _hoisted_3 = ["disabled"]
const _hoisted_4 = ["disabled"]
const _hoisted_5 = ["for"]
const _hoisted_6 = { class: "invalid-feedback" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SearchModal = _resolveComponent("SearchModal")!
  const _component_b_icon_x = _resolveComponent("b-icon-x")!
  const _component_b_icon_search = _resolveComponent("b-icon-search")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.fieldSearchModal.display)
      ? (_openBlock(), _createBlock(_component_SearchModal, {
          key: 0,
          multiple: _ctx.multiple,
          primaryKey: _ctx.fieldSearchModal.primaryKey,
          excludedKeys: _ctx.fieldSearchModal.excludedKeys,
          searchPath: _ctx.fieldSearchModal.searchPath,
          text: _ctx.fieldSearchModal.text,
          title: _ctx.fieldSearchModal.title,
          enableX: _ctx.fieldSearchModal.enableX,
          okBtn: _ctx.fieldSearchModal.okBtn,
          cancelBtn: _ctx.fieldSearchModal.cancelBtn,
          onOk: _ctx.fieldSearchModal.ok,
          onCancel: _ctx.fieldSearchModal.cancel
        }, null, 8, ["multiple", "primaryKey", "excludedKeys", "searchPath", "text", "title", "enableX", "okBtn", "cancelBtn", "onOk", "onCancel"]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("input", {
        list: "datalistOptions",
        class: _normalizeClass(["form-control", {'is-invalid': _ctx.errors, 'isDisabled': _ctx.readonly}]),
        id: _ctx.id,
        readonly: true,
        disabled: true,
        placeholder: _ctx.label,
        value: _ctx.fakeValue,
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.showSearchModal && _ctx.showSearchModal(...args))),
        onInput: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleInput && _ctx.handleInput(...args)))
      }, null, 42, _hoisted_2),
      (_ctx.fakeValue != '')
        ? (_openBlock(), _createElementBlock("button", {
            key: 0,
            class: "btn btn-outline-secondary",
            disabled: _ctx.readonly,
            type: "button",
            onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.clearValue && _ctx.clearValue(...args)))
          }, [
            _createTextVNode(" Ryd "),
            _createVNode(_component_b_icon_x)
          ], 8, _hoisted_3))
        : _createCommentVNode("", true),
      _createElementVNode("button", {
        class: "btn btn-outline-secondary",
        disabled: _ctx.readonly,
        type: "button",
        onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.showSearchModal && _ctx.showSearchModal(...args)))
      }, [
        _createTextVNode(" Søg "),
        _createVNode(_component_b_icon_search)
      ], 8, _hoisted_4),
      _createElementVNode("label", {
        for: _ctx.id,
        class: "form-label"
      }, _toDisplayString(_ctx.label), 9, _hoisted_5),
      _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.errorMessage), 1)
    ])
  ], 64))
}