import { AxiosInstance } from "axios";

import defaultClass from './defaultClass';
import { urls } from './types/default.type';
import { ServiceAgreementItemDTO, ServiceAgreementItemsID } from './types/serviceAgreementItem.type';

export default class serviceAgreementItem extends defaultClass {
    protected _model: ServiceAgreementItemDTO;
    protected _primaryKey: string;

    constructor(api: AxiosInstance, deliveryAddressId: ServiceAgreementItemsID = null) {
        super(api);
        this._model = {
            ServiceAgreementItemsID: null,
            ServiceAgreementID: null,
            TaskDescriptionId: null,
            ProductId: null,
            ItemCount: null,
            Area: null,
            VisitsPerYear: null,
            HoursPrVisit: null,
            FreeVisits: null,
            Description: null,
            Amount: null,
        }

        this._primaryKey = 'ServiceAgreementItemsID';

    }

    

    public fill(serviceAgreementItem: ServiceAgreementItemDTO) {
        this._model = serviceAgreementItem;
    }

    get ServiceAgreementItemsID(){
        return this._model.ServiceAgreementItemsID;
    }

    set ServiceAgreementItemsID(value){
        this._model.ServiceAgreementItemsID = value;
    }

    get ServiceAgreementID(){
        return this._model.ServiceAgreementID;
    }

    set ServiceAgreementID(value){
        this._model.ServiceAgreementID = value;
    }

    get TaskDescriptionId(){
        return this._model.TaskDescriptionId;
    }

    set TaskDescriptionId(value){
        this._model.TaskDescriptionId = value;
    }

    get ProductId(){
        return this._model.ProductId;
    }

    set ProductId(value){
        this._model.ProductId = value;
    }

    get ItemCount(){
        return this._model.ItemCount;
    }

    set ItemCount(value){
        this._model.ItemCount = value;
    }

    get Area(){
        return this._model.Area;
    }

    set Area(value){
        this._model.Area = value;
    }

    get VisitsPerYear(){
        return this._model.VisitsPerYear;
    }

    set VisitsPerYear(value){
        this._model.VisitsPerYear = value;
    }

    get HoursPrVisit(){
        return this._model.HoursPrVisit;
    }

    set HoursPrVisit(value){
        this._model.HoursPrVisit = value;
    }

    get FreeVisits(){
        return this._model.FreeVisits;
    }

    set FreeVisits(value){
        this._model.FreeVisits = value;
    }

    get Description(){
        return this._model.Description;
    }

    set Description(value){
        this._model.Description = value;
    }

    get Amount(){
        return this._model.Amount;
    }

    set Amount(value){
        this._model.Amount = value;
    }

    

}