import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0053f992"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["id", "readonly", "disabled", "placeholder", "value"]
const _hoisted_2 = ["for"]
const _hoisted_3 = { class: "invalid-feedback" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([(_ctx.compact) ? '': 'form-floating', "mb-3"])
  }, [
    _createElementVNode("input", {
      type: "text",
      class: _normalizeClass(["form-control", {'is-invalid': _ctx.errors}]),
      id: _ctx.id,
      readonly: _ctx.readonly,
      disabled: _ctx.readonly,
      placeholder: _ctx.label,
      value: _ctx.modelValue,
      onBlur: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleBlur && _ctx.handleBlur(...args))),
      onInput: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleInput && _ctx.handleInput(...args)))
    }, null, 42, _hoisted_1),
    (_ctx.showLabel)
      ? (_openBlock(), _createElementBlock("label", {
          key: 0,
          for: _ctx.id,
          class: "form-label"
        }, _toDisplayString(_ctx.label), 9, _hoisted_2))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.errorMessage), 1)
  ], 2))
}