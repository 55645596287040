
    import { defineComponent } from 'vue';
    
    import ServiceTaskRow from './ServiceTaskRow.vue'

    export default defineComponent({
        components: {
            ServiceTaskRow
        },
        props: {
            serviceContract: {
                type: Object,
                required: true
            },
            index: {
                type: Number,
                required: true
            },
            field: {
                type: Object,
                required: true
            },
        },
        data: function() {
            return {

            }
        },
        methods: {
            addRow() {
                this.field.value.push({
                    TaskDescriptionGroupID: null,
                    ProductCatalogPriceListID: null,
                    Area: null,
                    VisitsPerYear: null,
                    FreeVisits: null,
                    HoursPerVisit: null,
                    ItemCount: null,
                    SalesPrice: null
                });
            },

            deleteRow(index: number) {
                this.field.value.splice(index, 1);
            }
        },
        computed: {
            deletable() {
                return true;
                return this.field.value.length > 1;
            }
        },
        beforeMount() {
            if (this.field.value == '') {
                //this.addRow();
            }
        },
        watch: {
        },
    });
