import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6bfd61dc"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["id", "placeholder", "value"]
const _hoisted_2 = { class: "invalid-feedback" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([(_ctx.compact) ? '': 'form-floating', "mb-3"]),
    style: _normalizeStyle('display:'+_ctx.display)
  }, [
    _createElementVNode("button", {
      type: "button",
      class: _normalizeClass(["btn btn-primary", {}]),
      id: _ctx.id,
      placeholder: _ctx.label,
      value: _ctx.modelValue,
      onBlur: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleBlur && _ctx.handleBlur(...args))),
      onInput: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleInput && _ctx.handleInput(...args))),
      onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('click', {'action': _ctx.action})))
    }, _toDisplayString(_ctx.label), 41, _hoisted_1),
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.errorMessage), 1)
  ], 6))
}