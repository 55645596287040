
    import { defineComponent, triggerRef } from 'vue';
    
    import StepProgess from '@/components/widgets/StepProgress.vue'
    import ConfirmDialog from '@/components/widgets/ConfirmDialog.vue'
    import SearchModal from '@/components/widgets/SearchModal.vue'
    import ServiceContract from '../helpers/ServiceContract'
    import Summary from '../components/Summary.vue'
    import Fields from '../components/Fields.vue'
    import { Column, Section, Field, Hidden, fieldValidation } from '../helpers/Fields.interface'

    export default defineComponent({
        components: {
            StepProgess,
            ConfirmDialog,
            SearchModal,
            Summary,
            Fields
        },
        data: function() {
            return {
                serviceContract: new ServiceContract(this.$router, this.$route, this.$root?.$data.API),
                errors: false,
                ready: false,
                dev: {
                    fieldState: {} as fieldValidation
                },
                restoreDialog: {
                    display: false,
                    title: 'Forsæt fra hvor du slap',
                    text: 'Vil du genoptage sidste indtastning?',
                    okBtn: 'Ja',
                    enableX: false,
                    cancelBtn: 'Nej',
                    ok: () => { this.restore() },
                    cancel: () => { this.destroy() }
                },
                loadCustomerDialog: {
                    display: false,
                    title: 'Er du sikker?',
                    text: 'Hvis du indlæser en eksisterende kun vil alle ændringer vil gå tabt.',
                    okBtn: 'Ja',
                    enableX: false,
                    cancelBtn: 'Nej',
                    ok: () => { this.showImportCustomer() },
                    cancel: () => { this.hideLoadCustomerDialog() }
                },
                contractSavedDialog: {
                    display: false,
                    title: 'Færdig!',
                    text: 'Service Kontrakten er blevet gemt.',
                    okBtn: 'Ok',
                    enableX: true,
                    cancelBtn: false,
                    ok: () => { this._contractSavedDialogOk() },
                    cancel: () => {  }
                },
                customerModal: {
                    display: false,
                    title: 'Find kunde',
                    searchPath: 'customer/search',
                    text: 'Søg efter eksisterende kunde',
                    okBtn: 'Vælg',
                    enableX: true,
                    excludedKeys: ['CustomerID'],
                    primaryKey: 'CustomerID',
                    cancelBtn: 'Fortryd',
                    ok: (customer: any) => { 
                        this.customerSelected(customer)
                    },
                    cancel: () => { this.hideImportCustomer() }
                },
                showImportCustomerModal: false
            }
        },
        methods: {
            autoFill: function() {
                this.serviceContract.autoFill();
            },

            goToStep: function(step: number): void {
                if (step > this.serviceContract.maxStep) {
                    return;
                }

                this.serviceContract.currentStep = step;
            },

            addSection: function() {
                this.serviceContract.addSection();
            },

            async waitForReady() {
                while(this.serviceContract.ready === false) {
                    await new Promise(resolve => setTimeout(resolve, 50));
                }

                this.ready = this.serviceContract.ready;
                this.errors = this.serviceContract.errors;

                if (this.serviceContract.restorePossible) {
                    this.restoreDialog.display = true;
                }

            },

            async previousStep() {
                this.ready = false;
                await this.serviceContract.previousStep();
                this.ready = true;
            },

            async nextStep() {
                try {
                    this.ready = false;
                    await this.serviceContract.nextStep();
                    this.ready = true;
                } catch (e) {
                    this.errors = true;
                    this.ready = true;
                }
            },
            downloadContract() {
                this.serviceContract.downloadContract()
            },

            restore() {
                this.closeRestoreDialog();
                this.serviceContract.restore();
            },

            destroy() {
                this.closeRestoreDialog();
                this.serviceContract.destroy();
            },

            closeRestoreDialog() {
                this.restoreDialog.display = false;
            },

            importCustomer() {
                if (this.serviceContract.hasChanges) {
                    this.showLoadCustomerDialog();
                } else {
                    this.showImportCustomer();
                }
            },

            customerSelected(customer: any) {
                this.serviceContract.customer = customer;
                this.hideImportCustomer();
            },

            showImportCustomer() {
                this.customerModal.display = true;
                this.hideLoadCustomerDialog();
            },

            hideImportCustomer() {
                this.customerModal.display = false;
            },

            hideLoadCustomerDialog() {
                this.loadCustomerDialog.display = false;
            },

            showLoadCustomerDialog() {
                this.loadCustomerDialog.display = true;
            },

            _contractSavedDialogOk() {
                this.contractSavedDialog.display = false;
                this.serviceContract.contractSaved = false;
            },

            showField: function(field: Field): Boolean {
                if (field.hidden == undefined) {
                    return true;
                }

                return this.operator(field.hidden);
            },

            operator: function(hidden: Hidden) {
                const operators: any = {
                    '==': function(a: string | number, b: string | number) { return a == b },
                    '!=': function(a: string | number, b: string | number) { return a != b },
                };

                if (hidden.operator in operators)
                {
                    return !operators[hidden.operator](this.serviceContract.getFieldValue(hidden.field, 99), hidden.value);
                }
                return false;
            },

            fakeSaved() {
                this.serviceContract._contractSaved = true;
            }
        },
        computed: {
            hasFieldErrors: function() {
                this.dev.fieldState = {};
                if (!this.ready) {
                    return true;
                }

                if (!this.serviceContract.hasFields) {
                    return false;
                }
                
                let hasErrors = false;
                const _this = this;

                const checkColumnForErrors = (column: Column): boolean => {
                    column.fields.forEach(field => {
                        this.dev.fieldState[field.label as keyof Object] = true;
                        if (!this.showField(field)) {
                            return false;
                        }
                        if (field.errors  == true) {
                            hasErrors = true;
                            this.dev.fieldState[field.label as keyof Object] = false;
                            return true;
                        } else if (/required/.test(field.rules ?? '') && field.value == '' && _this.showField(field)) {
                            hasErrors = true;
                            this.dev.fieldState[field.label as keyof Object] = false;
                            return true;
                        }
                    });
                    return false;
                }

                if (this.serviceContract.canBeArray)
                {
                    this.serviceContract.values.forEach((value, index) => {
                        value.forEach((section: Section) => {
                            section.columns.forEach(column => {
                                if (checkColumnForErrors(column)) {
                                    return true;
                                }
                            });
                        });
                    });
                } else {
                    this.serviceContract.fields.forEach(section => {
                        section.columns.forEach(column => {
                            if (checkColumnForErrors(column)) {
                                return true;
                            }
                        });
                    });
                }

                return hasErrors;
            }
        },
        mounted() {
            this.waitForReady();
        },
        watch: {
            'serviceContract.contractSaved': function() {
                if (this.serviceContract.contractSaved) {
                    this.contractSavedDialog.display = true;
                }
            }
        },
    });
