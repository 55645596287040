import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-25bbc8a7"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "row mb-3" }
const _hoisted_2 = { class: "col" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = {
  key: 0,
  class: "col-6"
}
const _hoisted_5 = { class: "col" }
const _hoisted_6 = ["name", "id", "readonly", "disabled", "checked"]
const _hoisted_7 = ["for"]
const _hoisted_8 = { class: "col" }
const _hoisted_9 = ["name", "id", "readonly", "disabled", "checked"]
const _hoisted_10 = ["for"]
const _hoisted_11 = { class: "row" }
const _hoisted_12 = { class: "col" }
const _hoisted_13 = { class: "invalid-feedback" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        class: _normalizeClass(["border rounded", _ctx.compact ? 'p-1' : 'p-3'])
      }, [
        _createElementVNode("div", _hoisted_3, [
          (_ctx.showLabel)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(_ctx.label), 1))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("input", {
              class: _normalizeClass(["form-check-input", {'is-invalid': _ctx.errors}]),
              type: "radio",
              name: 'input_'+_ctx.id,
              id: _ctx.id,
              readonly: _ctx.readonly,
              value: "1",
              disabled: _ctx.readonly,
              onBlur: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleBlur && _ctx.handleBlur(...args))),
              onInput: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleInput && _ctx.handleInput(...args))),
              checked: _ctx.modelValue == '1'
            }, null, 42, _hoisted_6),
            _createElementVNode("label", {
              class: "form-check-label",
              for: 'input_'+_ctx.id
            }, " Ja ", 8, _hoisted_7)
          ]),
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("input", {
              class: _normalizeClass(["form-check-input", {'is-invalid': _ctx.errors}]),
              type: "radio",
              name: 'input_'+_ctx.id,
              id: _ctx.id,
              readonly: _ctx.readonly,
              value: "0",
              disabled: _ctx.readonly,
              onBlur: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.handleBlur && _ctx.handleBlur(...args))),
              onInput: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.handleInput && _ctx.handleInput(...args))),
              checked: _ctx.modelValue == '0'
            }, null, 42, _hoisted_9),
            _createElementVNode("label", {
              class: "form-check-label",
              for: 'input_'+_ctx.id
            }, " Nej ", 8, _hoisted_10)
          ])
        ]),
        _createElementVNode("div", _hoisted_11, [
          _createElementVNode("div", _hoisted_12, [
            _createElementVNode("div", _hoisted_13, _toDisplayString(_ctx.errorMessage), 1)
          ])
        ])
      ], 2)
    ])
  ]))
}