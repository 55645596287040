
import { defineComponent, PropType } from 'vue';
import { Field, Fields, Step } from '@/helpers/Fields.interface';
import FieldComponent from '@/components/layout/Field.vue';


export default defineComponent({
    components: {
        FieldComponent
    },
    emits: ['update:modelValue', 'update:field', 'update:value', 'update:error', 'click'],
    props: {
        modelValue: {
            type: Object as PropType<Field[]>, 
            required: true, 
        }
    }, 
    methods: {
        structuredField(section: number, column: number, row: number, event: Field) { 
            if (event.id) { 
                let field = this.modelValue.find((field: Field) => field.id == event.id);
                if (field) {
                    field = event;
                }
            }
            this.$emit('update:modelValue', this.modelValue);
            if (event) {
                this.$emit('update:field', event)
            }
        }, 
        click(event: any, index: number) {
            event.index = index;
            this.$emit('click', event);
        },
        updateError(event: Event){ this.$emit('update:error', event)},
        updateValue(event: Event) { this.$emit('update:value', event)}
    }, 
    computed: {
        /**
         * Creates a list of lists of Fields, 
         * This represents the columns of fields
         */
        structuredFields(): Step { 
            let column = 0;
            let section = 0;
            const fields: Step = {
                sections: [
                    {
                        columns: [
                            {
                                fields: [],
                            }
                        ],
                    }
                ],
                values: []
            }
            for (let field in this.modelValue) {
                const fieldObject = <Field> this.modelValue[field];
                if (fieldObject.type == 'new_section') {
                    column = 0;
                    section++;
                    fields.sections[section] = {
                        columns: [
                            {
                                fields: [],
                            }
                        ],
                    }
                    continue;
                }
                if (fieldObject.type == 'new_row') {
                    column = 0;
                    section++;
                    fields.sections[section] = {
                        row: true,
                        columns: [
                            {
                                fields: [],
                            }
                        ],
                    }
                    continue;
                }
                if (fieldObject.type == 'new_column') {
                    column++;
                    fields.sections[section].columns[column] =  {
                        fields: [],
                    }
                    continue;
                }

                fieldObject.errors = false;

                fields.sections[section].columns[column].fields.push(fieldObject);
            }
            return fields;
        }
    }
});

