import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FieldComponent = _resolveComponent("FieldComponent")!

  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.structuredFields.sections, (section, sectionIndex) => {
    return (_openBlock(), _createElementBlock("form", {
      class: "needs-validation row",
      novalidate: "",
      key: sectionIndex
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(section.columns, (column, columnIndex) => {
        return (_openBlock(), _createElementBlock("div", {
          class: _normalizeClass({col: true,  'mb-4':!(section?.row ?? false)}),
          key: columnIndex
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(column.fields, (field, index) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "row",
              key: index
            }, [
              _createVNode(_component_FieldComponent, {
                modelValue: field,
                "onUpdate:modelValue": ($event: any) => (_ctx.structuredField(sectionIndex, columnIndex, index, $event)),
                "onUpdate:error": _ctx.updateError,
                "onUpdate:value": _ctx.updateValue,
                onClick: x => _ctx.click(x, field.id)
              }, null, 8, ["modelValue", "onUpdate:modelValue", "onUpdate:error", "onUpdate:value", "onClick"])
            ]))
          }), 128))
        ], 2))
      }), 128))
    ]))
  }), 128))
}