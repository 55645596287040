
import { defineComponent } from 'vue';
import { Field, stringArray, Hidden, serviceTask } from '../helpers/Fields.interface';

    export default defineComponent({
        components: {
        },
        props: {
            serviceContract: {
                type: Object,
                required: true
            },
        },
        data() {
            return {
                descriptionText: '',
                valueCache: {} as stringArray
            }
        },
        mounted() {
        },
        methods: {

            getRealValue(field: Field) {
                if (field.type == 'dropdown') {
                    return this.getDropDownRealValue(field);
                } else if (field.type == 'search') {
                    return this.getSearchRealValue(field);
                } else {
                    return field.value;
                }
            },

            filtered(fields: Field[]) {
                const newFields: Field[] = JSON.parse(JSON.stringify(fields));
                return newFields.filter((field: Field) => {
                    if (this.showInSummary(field)) {
                        return field;
                    }
                });
            },

            filtered2(row: serviceTask) {
                const newRow: serviceTask = JSON.parse(JSON.stringify(row));
                for(let field in newRow) {
                    const show = this.showInSummary2(field)
                    if (!show) {
                        delete newRow[field as keyof serviceTask];
                    }
                }
                return newRow;
                
            },

            showInSummary(field: Field): Boolean {
                console.log("field", field);
                if (!field || typeof(field.summary) === 'undefined' || field.summary == true) {
                    return true;
                }
                return false;
            },

            showInSummary2(fieldId: any) {
                const taskFields: any = JSON.parse(JSON.stringify(this.serviceContract.serviceTaskFields));
                const field = taskFields.filter((field: Field) => {
                    if (field.id?.split('.')[1] == fieldId) {
                        return field;
                    } else {
                        console.log('Couldn\'t find field', fieldId);
                    }
                });
                console.log("Summary2Field", field);
                return this.showInSummary(field[0]);
            },

            getSearchRealValue(field: Field) {
                if (field.value == '' || field.loading == true) {
                    return field.fakeValue;
                }
                field.fakeValue = 'Henter værdi...';
                field.loading = true;
                const config = {
                    url: field.values,
                    method: 'get',
                }

                const hash: string = btoa(field.values ?? '');
                if (this.valueCache[hash]) {
                    return this.valueCache[hash as keyof Object];
                } else {
                    this.valueCache[hash] = 'Henter værdi...';
                }

                if (field.multiple && field.value) {
                    const ids = field.value.toString().split(',');
                    for (let i = 0; i < ids.length; i++) {
                        this.getSearchValue(ids[i], field, hash);
                    }
                    return;
                }
                this.getSearchValue(field.value, field, hash);

                return field.fakeValue;
            },

            getSearchValue(id: any, field: Field, hash: string) {
                if (id == null || id == '')
                {
                    return;
                }
                const config = {
                    url: field.values,
                    method: 'get',
                    params: {
                        id: id
                    }
                }

                this.$root?.$data.API.request(config).then((response: any) => {
                    if (field.fakeValue != 'Henter værdi...') {
                        field.fakeValue += ', ';
                    } else {
                        field.fakeValue = '';
                    }

                    field.fakeValue += Object.values(response.data)[0] as string;
                    this.valueCache[hash] = field.fakeValue?? '';
                })
                .catch((error: any) => {
                    field.fakeValue = 'Kunne ikke hente værdi';
                    console.error(error);
                })
            },

            getDropDownRealValue(field: Field) {
                if (field.value == '' || field.loading == true) {
                    return field.fakeValue;
                }
                field.fakeValue = 'Henter værdi...';
                field.loading = true;
                const config = {
                    url: field.values,
                    method: 'get',
                }

                const hash: string = btoa(field.values ?? '');
                if (this.valueCache[hash]) {
                    return this.valueCache[hash as keyof Object];
                } else {
                    this.valueCache[hash] = 'Henter værdi...';
                }

                this.$root?.$data.API.request(config).then((response: any) => {
                    field.fakeValue = response.data[field.value as string];
                    const data = response.data;
                    if (typeof(data[field.value as string]) == 'string') {
                        field.fakeValue = data[field.value as string];
                    } else {
                        field.fakeValue = data[field.value as string].ServiceDescription;
                    }
                    this.valueCache[hash] = field.fakeValue?? '';
                })
                .catch((error: any) => {
                    field.fakeValue = 'Kunne ikke hente værdi';
                })
                return field.fakeValue;
            },

            prepareValues(values: string, taskRow: any): string {
                let rowValues = values.slice();
                rowValues.match(/{([^}]+)}/g)?.forEach((match: any) => {
                    const fieldValue = taskRow[match.replace('{', '').replace('}', '')];
                    if (fieldValue) {
                        rowValues = rowValues.replace(match, fieldValue);
                    }
                });
                return rowValues;
            },

            createFakeField(fieldId: any, fieldValue: string, taskRow: any): Field {
                const index: number = this.serviceContract.serviceTaskFields.findIndex((f: Field) => f.id?.split('.')[1] == fieldId);
                if (index != -1) {
                    const field: Field = JSON.parse(JSON.stringify(this.serviceContract.serviceTaskFields[index]));
                    field.value = fieldValue;
                    field.values = this.prepareValues(field.values ?? '', taskRow);
                    return field;
                }

                const fakeField: Field = {
                    id: 'fakeField',
                    value: 'Noget gik galt!',
                    type: 'text',
                    errors: true
                }

                return fakeField;
                
            },
            showField: function(field: Field, index: number): Boolean {
                if (field.type == 'custom') {
                    return false;
                }
                if (field.hidden == undefined) {
                    return true;
                }

                return this.operator(field.hidden, index);
            },

            operator: function(hidden: Hidden, index: number) {
                const operators: any = {
                    '==': function(a: string | number, b: string | number) { return a == b },
                    '!=': function(a: string | number, b: string | number) { return a != b },
                };

                if (hidden.operator in operators)
                {
                    return !operators[hidden.operator](this.serviceContract.getFieldValue(hidden.field, index), hidden.value);
                }
                return false;
            },
            getOptions(url: string) {
                
            },
        },
    });
