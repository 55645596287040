
    import { defineComponent } from 'vue';
    
    import TextInput from '@/components/inputs/TextInput.vue'
    import NumberInput from '@/components/inputs/NumberInput.vue'
    import DropdownInput from '@/components/inputs/DropdownInput.vue'
    import { Field } from '../helpers/Fields.interface'


    export default defineComponent({
        emits: ['deleteRow'],
        components: {
            TextInput,
            NumberInput,
            DropdownInput,
        },
        props: {
            serviceContract: {
                type: Object,
                required: true
            },
            taskRow: {
                type: Object,
                required: true
            },
            deletable: {
                type: Boolean,
                required: false,
                default: false
            },
        },
        data: function() {
            return {
                fields: {} as Field[],
                price: 0,
                itemType: '',
            }
        },
        methods: {
            deleteRow() {
                this.$emit('deleteRow');
            },

            getPrice(priceObj: any) {
                if (!priceObj || !priceObj.SalesPrice) {
                    return;
                }

                if (priceObj.ItemType)
                {
                    this.itemType = priceObj.ItemType;
                }

                this.price = parseFloat(priceObj.SalesPrice);
            },

            getFieldKey(fieldId: string | undefined) {
                if (!fieldId)
                    return 0;

                return fieldId?.split('.')[1];
            },

            isReady(fieldId: string | undefined) {
                const fieldKey = this.getFieldKey(fieldId);

                switch (fieldKey) {
                    case 'TaskDescriptionGroupID':
                        return true;
                    case 'ProductCatalogPriceListID':
                        return this.taskRow.TaskDescriptionGroupID != null;
                    case 'Area':
                        return this.taskRow.ProductCatalogPriceListID != null;
                    case 'VisitsPerYear':
                        return this.taskRow.ProductCatalogPriceListID != null && this.itemType == 'Timepris';
                    case 'HoursPerVisit':
                        return this.taskRow.ProductCatalogPriceListID != null && this.itemType == 'Timepris';
                    case 'ItemCount':
                        return this.taskRow.ProductCatalogPriceListID != null && this.itemType == 'Styk';
                    case 'SalesPrice':
                        return this.taskRow.ProductCatalogPriceListID != null;
                    case 'FreeVisits':
                        return this.taskRow.ProductCatalogPriceListID != null && this.itemType == 'Timepris';
                }

                console.log(fieldKey);
            },

            getDropdownKey(fieldId: string | undefined) {
                const fieldKey = this.getFieldKey(fieldId);

                
                switch (fieldKey) {
                    case 'ProductCatalogPriceListID':
                        return 'ServiceDescription';
                }

                return false;
            },

            prepareValues(values: any) {
                let rowValues = values.slice();
                rowValues.match(/{([^}]+)}/g)?.forEach((match: any) => {
                    const fieldValue = this.taskRow[match.replace('{', '').replace('}', '')];
                    if (fieldValue) {
                        rowValues = rowValues.replace(match, fieldValue);
                    }
                });
                return rowValues;
            },

            calculatePrice() {
                let totalPrice = 0;

                if (this.itemType == 'Styk')
                {
                    const productCount = (/^[0-9]+$/.test(this.taskRow.ItemCount)) ? parseInt(this.taskRow.ItemCount) : 0;
                    totalPrice = productCount * this.price;
                } else {
                    const visitCount = (/^[0-9]+$/.test(this.taskRow.VisitsPerYear)) ? parseInt(this.taskRow.VisitsPerYear) : 0;
                    const hoursCount = (/^[0-9]+$/.test(this.taskRow.HoursPerVisit)) ? parseInt(this.taskRow.HoursPerVisit) : 0;

                    totalPrice = hoursCount * visitCount * this.serviceContract.hourPrice;
                }

                this.taskRow.SalesPrice = totalPrice.toString();

            }
        },
        mounted() {
            const fields: Field[] = JSON.parse(JSON.stringify(this.serviceContract.serviceTaskFields));
            this.fields = fields;
        },
        watch: {
            "taskRow.TaskDescriptionGroupID": function (newVal) {
                    this.taskRow.ProductCatalogPriceListID = null;
                    this.taskRow.Area = null;
                    this.taskRow.VisitsPerYear = null;
                    this.taskRow.FreeVisits = null;
                    this.taskRow.HoursPerVisit = null;
                    this.taskRow.ItemCount = null;
                    this.taskRow.SalesPrice = "0";
                    this.calculatePrice();
            },

            "taskRow.ProductCatalogPriceListID": function () {
                this.taskRow.VisitsPerYear = null;
                this.taskRow.FreeVisits = null;
                this.taskRow.HoursPerVisit = null;
                this.taskRow.ItemCount = null;
                this.taskRow.SalesPrice = "0";
                this.calculatePrice();
            },

            "taskRow.VisitsPerYear": function () {
                this.calculatePrice();
            },
            
            "taskRow.HoursPerVisit": function () {
                this.calculatePrice();
            },

            "taskRow.ItemCount": function () {
                this.calculatePrice();
            }

        },
    });
