import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = {
  key: 0,
  class: "col-auto mx-0 px-0"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TextInput = _resolveComponent("TextInput")!
  const _component_NumberInput = _resolveComponent("NumberInput")!
  const _component_DropdownInput = _resolveComponent("DropdownInput")!
  const _component_b_icon_trash = _resolveComponent("b-icon-trash")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.fields, (taskField, fi) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "col",
        key: fi
      }, [
        (taskField.type == 'text')
          ? (_openBlock(), _createBlock(_component_TextInput, {
              key: 0,
              showLabel: false,
              compact: true,
              readonly: taskField.readonly || !_ctx.isReady(taskField?.id),
              id: taskField.id,
              errors: taskField.errors,
              "onUpdate:errors": ($event: any) => ((taskField.errors) = $event),
              modelValue: _ctx.taskRow[_ctx.getFieldKey(taskField.id)],
              "onUpdate:modelValue": ($event: any) => ((_ctx.taskRow[_ctx.getFieldKey(taskField.id)]) = $event)
            }, null, 8, ["readonly", "id", "errors", "onUpdate:errors", "modelValue", "onUpdate:modelValue"]))
          : _createCommentVNode("", true),
        (taskField.type == 'number')
          ? (_openBlock(), _createBlock(_component_NumberInput, {
              key: 1,
              showLabel: false,
              compact: true,
              readonly: taskField.readonly || !_ctx.isReady(taskField.id),
              id: taskField.id,
              errors: taskField.errors,
              "onUpdate:errors": ($event: any) => ((taskField.errors) = $event),
              modelValue: _ctx.taskRow[_ctx.getFieldKey(taskField.id)],
              "onUpdate:modelValue": ($event: any) => ((_ctx.taskRow[_ctx.getFieldKey(taskField.id)]) = $event)
            }, null, 8, ["readonly", "id", "errors", "onUpdate:errors", "modelValue", "onUpdate:modelValue"]))
          : _createCommentVNode("", true),
        (taskField.type == 'dropdown')
          ? (_openBlock(), _createBlock(_component_DropdownInput, {
              key: 2,
              showLabel: false,
              compact: true,
              optionKey: _ctx.getDropdownKey(taskField.id),
              readonly: taskField.readonly || !_ctx.isReady(taskField.id),
              values: _ctx.prepareValues(taskField.values),
              ready: _ctx.isReady(taskField.id),
              id: taskField.id,
              errors: taskField.errors,
              "onUpdate:errors": ($event: any) => ((taskField.errors) = $event),
              modelValue: _ctx.taskRow[_ctx.getFieldKey(taskField.id)],
              "onUpdate:modelValue": ($event: any) => ((_ctx.taskRow[_ctx.getFieldKey(taskField.id)]) = $event),
              onFullvalue: _ctx.getPrice
            }, null, 8, ["optionKey", "readonly", "values", "ready", "id", "errors", "onUpdate:errors", "modelValue", "onUpdate:modelValue", "onFullvalue"]))
          : _createCommentVNode("", true)
      ]))
    }), 128)),
    (_ctx.deletable)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("button", {
            type: "button",
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.deleteRow && _ctx.deleteRow(...args))),
            class: "btn btn-link mx-0 px-0"
          }, [
            _createVNode(_component_b_icon_trash)
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}