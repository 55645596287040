import axios, { AxiosInstance } from "axios";

class APIclient {

    _version: string;
    _client: AxiosInstance;
    _token: string;
    _baseUrl: string;
    _cache: any = {};

    constructor() {
        this._version = 'v1';

        this._token = 'no-token-set';
        this._baseUrl = process.env.VUE_APP_API_BASE_URL+'/api/'+this._version+'/';
        this._client = axios.create({
            baseURL: this._baseUrl,
            headers: {
                "Content-type": "application/json",
            },
        });
    }

    request(configArguments: any): Promise<any> {
        this._setToken();
        let cacheable = false;
        if (configArguments.hasOwnProperty('cacheable') && configArguments.cacheable) { 
            delete configArguments.cacheable;
            cacheable = true;
        }

        const config = {...{
            headers: {
                'Authorization': "Bearer " + this._token
            }
        }, ...configArguments};
        if (cacheable) { 
            const cacheKey = JSON.stringify(config);
            if (this._cache[cacheKey]) {
                return this._cache[cacheKey]
            }
        }
        const result = this._client.request(config);
        if (cacheable) {
            const cacheKey = JSON.stringify(config);
            this._cache[cacheKey] = result;
        }
        return result;
            
    }

    _setToken() {
        this._token = localStorage.getItem('accessToken') ?? '';
    }
    

    path(path: string) {
        return this._baseUrl+path;
    }
}

export const API = new APIclient();