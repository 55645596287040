
    import { defineComponent } from 'vue';
    import AppList from '@/helpers/AppList';

    export default defineComponent({
        components: {
        },
        props: {
            smallText: {
                type: Boolean,
                default: false
            },
            currentPage: {
                type: String,
                default: ''}
        },
        data: function() {
            return {
                appList: new AppList().list
            }
        },
        mounted() {
        },
        methods: {
            isCurrentPage(page: string) {
                const regex = new RegExp('^'+page, 'i');
                return regex.test(this.currentPage);
            },
            closeAppMenu() {
                this.$emit('close');
            } 

        }
    });
